import React, { useEffect } from 'react';
import Information from './Tabs/Information/Information.component';
import { headerModel } from './IqsNewRequest.constants';

export const useIqsNewRequest = props => {
  const { fetchDropdownData, allDropdownData, language } = props;

  useEffect(() => {
    fetchDropdownData();
  }, [language]);

  const getHeaderModel = () => {
    const model = [...headerModel];
    model[2].options = allDropdownData?.IqRequestStatus || [];
    model[3].options = allDropdownData?.IqRequestType || [];
    model[4].options = allDropdownData?.IqRequestExecutionLocation || [];

    return model;
  };
  const tabs = [
    {
      id: 1,
      tabTitle: 'information',
      className: '',
      render: (isActive, props) => {
        return <Information isActive={isActive} {...props} />;
      },
    },
  ];

  return {
    tabs,
    getHeaderModel,
  };
};
