import React, { useCallback, useEffect, useState, useRef } from 'react';
import { SchedulerData, ViewTypes } from 'react-big-scheduler';
import { CellUnits } from 'react-big-scheduler';
import { DATE_FORMAT } from 'library/common/commonComponents/ReactBigScheduler';
import moment from 'moment';
import { dayViewConfig, MonthViewConfig, schedularConfig } from './config';
import cn from 'classnames';
import { scrollToTop } from 'library/utilities/scrollActions';
import { useSelector } from 'react-redux';
import ResourceItem from './components/ResourceItems.component';
import { isEqual } from 'lodash';
import { RESOURCE_TYPES } from 'library/common/commonConstants/kitConstants';
import * as $ from 'jquery';
import cloneDeep from 'lodash/cloneDeep';
import { checkStockRoles } from 'library/utilities/checkRoles';

let groupOnlySlots = ['comments', 'systems', 'equipment', 'other', 'person'];

const DAY_VIEW_START = '00:00';
const DAY_VIEW_END = '23:00';
const WEEK_VIEW_START = '06:00';
const WEEK_VIEW_END = '18:00';
const NON_WORKING_HOURS_BEFORE = '07:00';
const NON_WORKING_HOURS_AFTER = '17:00';
const COLLAPSABLE_PREFIX = 'collapsable__';

/**
 * Determines the background color for a time slot in the scheduler.
 * This function checks if the slot represents a group header, a location header, or a regular time slot.
 * It then applies different background colors based on these categories and whether the time slot falls within working hours.
 */
const renderCellBackgroundColor = (_, slotId, header) => {
  if (groupOnlySlots.includes(slotId)) {
    return '#6AB0E2';
  } else if (slotId?.includes(COLLAPSABLE_PREFIX)) {
    return '#C6DAF24D';
  }
  const itemTime = moment(header.time, 'YYYY-MM-DD HH:mm:ss').format('HH:mm');
  const nonWorkingStart = moment(NON_WORKING_HOURS_BEFORE, 'HH:mm');
  const nonWorkingEnd = moment(NON_WORKING_HOURS_AFTER, 'HH:mm');
  const currentTime = moment(itemTime, 'HH:mm');
  if (currentTime.isBetween(nonWorkingStart, nonWorkingEnd, 'h', '[]')) {
    return '#fff';
  } else {
    return '#DCE3E9';
  }
};

const renderNonWorkingHoursBackgroundColorForMonthView = (_, slotId, header) => {
  const isToday = moment(header.time).isSame(moment(), 'day');
  if (groupOnlySlots.includes(slotId)) {
    return '#6AB0E2';
  } else if (slotId?.includes(COLLAPSABLE_PREFIX)) {
    return '#C6DAF24D';
  }
  if (isToday) {
    return '#C9E0FF';
  } else if (header.nonWorkingTime) {
    return '#DCE3E9';
  } else {
    return '#fff';
  }
};

/**
 *  How ViewTypes are mapped in this schedular?
 *  ViewTypes.Custom: Week View
 *  ViewTypes.Custom1: Day View
 * ViewTypes.Custom2: Month View
 *  Refer: config.js for cell width and other configuations
 */
export const useAppSchedular = props => {
  const [calendarConfig, setcalendarConfig] = useState();
  const [selectedViewType, setSelectedViewType] = useState(ViewTypes.Custom2);
  const [hiddenResourceGroups, setHiddenResourceGroups] = useState([]);
  const schedulerRef = useRef();
  const [selectedDate, setSelectedDate] = useState(moment().format(DATE_FORMAT));
  const [selectedCommentEvent, setSelectedCommentEvent] = useState(null);
  const [selectedSystem, setSelectedSystem] = useState([]);
  const [eventSelectedPopupOpen, setEventSelectedPopupOpen] = useState(false);
  const [singleEventClickedId, setSingleEventClickedId] = useState(null);
  const {
    fetchIqsCalendarResources,
    filters: {
      filter: { stock, productHierarchy, systemClass, systemMainComponent, resourceType },
    },
    fetchIqsCommentsList,
    toggleActionMessage,
  } = props;
  const user = useSelector(state => state.authReducer.user);

  // const prevSchedularTracker = useRef(iqsCalendarResources);

  const { iqsCalendarResources, iqsAddedCommentList, iqsCommentsLocationsIds, iqsRequestEvents } = useSelector(state => state.calendar);

  useEffect(() => {
    // if(prevSchedularTracker)
    setCalendarData(selectedViewType);
    return () => {};
  }, [selectedDate, hiddenResourceGroups, iqsCalendarResources, selectedViewType, iqsAddedCommentList, iqsRequestEvents]);

  useEffect(() => {
    const dispalyStartDate = moment(selectedDate).startOf('month').format(DATE_FORMAT);
        const dispalyEndDate = moment(selectedDate).endOf('month').format(DATE_FORMAT);
    fetchIqsCalendarResources({
      stock,
      productHierarchy,
      systemClass,
      systemMainComponent,
      dispalyStartDate,
      dispalyEndDate,
    });
  }, [
    JSON.stringify(stock),
    JSON.stringify(productHierarchy),
    JSON.stringify(systemClass),
    JSON.stringify(systemMainComponent),
    moment(selectedDate).startOf('month').format('YYYY-MM')
  ]);
  useEffect(() => {
      if (iqsCommentsLocationsIds?.comments?.length > 0) {
        const locationIds = iqsCommentsLocationsIds.comments.map(comment => comment.stockId);
        const dispalyStartDate = moment(selectedDate).startOf('month').format(DATE_FORMAT);
        const dispalyEndDate = moment(selectedDate).endOf('month').format(DATE_FORMAT);
        fetchIqsCommentsList({ ids: locationIds }, dispalyStartDate, dispalyEndDate);
      }

    // Cleanup function (if needed)
    return () => {};
  }, [
    iqsCommentsLocationsIds,
    moment(selectedDate).startOf('month').format('YYYY-MM') // Dependency to check if the month has changed
  ]);

  useEffect(() => {
    if (iqsCalendarResources && iqsCalendarResources.length > 0) {
      setCalendarData(selectedViewType);
    }
  }, [iqsAddedCommentList]);

  /**
   * This useEffect is respsonsible for DOM manipulation directly
   */
  useEffect(() => {
    // Use case: based on this class the antd popover default styles are overridden, refer css
    document.body.classList.add('iqs-calendar-active');
    removeBorderForResourceSections();

    let schedularRef = document.querySelector('.scheduler-view');
    let schedulerBody = null;
    let listener = null;
    let timeoutId = null;
    const syncScroll = () => {
      if (schedularRef) {
        const schedulerHeader = schedularRef.children[0];
        schedulerBody = schedularRef.children[1];

        listener = () => {
          if (schedulerHeader && schedulerBody) {
            schedulerHeader.scrollLeft = schedulerBody.scrollLeft;
          }
        };
        schedulerBody.addEventListener('scroll', listener);
      }
    };

    timeoutId = setTimeout(syncScroll, 500);

    return () => {
      document.body.classList.remove('iqs-calendar-active');
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      if (schedulerBody && listener) {
        schedulerBody.removeEventListener('scroll', listener);
      }
    };
  }, [calendarConfig]);

  /**
   * This useEffect is repsonsible for handling the resourceType changes
   * It will close and the other section of schedular keeping the selected resourcetype open
   * If the list is empty we shall show all sections open
   */
  useEffect(() => {
    if (resourceType && resourceType.length > 0) {
      const typeMapping = {
        [RESOURCE_TYPES.EQUIPMENT]: 'equipment',
        [RESOURCE_TYPES.SYSTEM]: 'systems',
        [RESOURCE_TYPES.PERSON]: 'person',
        [RESOURCE_TYPES.OTHER]: 'other',
      };
      const visibleResourceIds = new Set();
      resourceType.forEach(type => {
        const resourceTypeKey = typeMapping[type];
        if (resourceTypeKey) {
          const matchingResources = hideRecursively(iqsCalendarResources, resourceTypeKey);
          matchingResources.forEach(resourceId => visibleResourceIds.add(resourceId));
        }
      });
      const allResourceIds = iqsCalendarResources.map(resource => resource.id);
      const hiddenResources = allResourceIds.filter(id => !visibleResourceIds.has(id));
      setHiddenResourceGroups(hiddenResources);
    } else {
      setHiddenResourceGroups([]);
    }
  }, [JSON.stringify(resourceType)]);

  const setCalendarData = viewType => {
    let nonHiddenResources = iqsCalendarResources.filter(item => !hiddenResourceGroups.includes(item.parentId));
    let config = { ...schedularConfig };
    if (viewType === ViewTypes.Custom1) {
      config = { ...config, ...dayViewConfig };
    }
    if (viewType === ViewTypes.Custom2) {
      config = { ...config, ...MonthViewConfig };
    }
    let schedulerData1 = new SchedulerData(
      selectedDate,
      viewType,
      false,
      false,
      { ...config },
      {
        getCustomDateFunc: getCustomDate,
        nonAgendaDayCellHeaderRenderer: renderDayHeader,
        getNonAgendaViewBodyCellBgColorFunc:
          selectedViewType === ViewTypes.Custom2
            ? renderNonWorkingHoursBackgroundColorForMonthView
            : renderCellBackgroundColor,
      },
    );

    schedulerData1.setResources(nonHiddenResources);
    schedulerData1.setEvents([...iqsAddedCommentList, ...iqsRequestEvents]);
    const clonedData = cloneDeep(schedulerData1);
    setcalendarConfig(clonedData);
  };

  /**
   * Removes the border between rows in the scheduler's background table that belong to the same resource section.
   * This function directly manipulates the DOM to achieve the desired visual effect.
   * It sets a `data-id` attribute on each row in the background table,
   * which can then be used in CSS to conditionally apply or remove borders.
   * The `setTimeout` ensures that the DOM is fully rendered before the manipulation occurs.
   */
  const removeBorderForResourceSections = () => {
    setTimeout(() => {
      const schedularBgTableRowsRef = document.querySelectorAll('.scheduler-bg-table tbody tr');
      if (schedularBgTableRowsRef && schedularBgTableRowsRef.length && calendarConfig && calendarConfig?.resources) {
        schedularBgTableRowsRef.forEach((row, index) => {
          row.setAttribute('data-id', calendarConfig?.resources[index]?.id || '');
        });
      }
    }, 0);
  };

  const getCustomDate = (schedulerData, num, date = undefined) => {
    const { viewType } = schedulerData;
    let selectDate = schedulerData.startDate;
    if (date != undefined) selectDate = date;

    let startDate =
        num === 0
          ? selectDate
          : schedulerData
              .localeMoment(selectDate)
              .add(1 * num, 'days')
              .format(DATE_FORMAT),
      endDate = schedulerData.localeMoment(startDate).add(1, 'days').format(DATE_FORMAT),
      cellUnit = CellUnits.Hour;

    if (viewType === ViewTypes.Custom) {
      let monday = schedulerData.localeMoment(selectDate).startOf('week').day(1);
      startDate = num === 0 ? monday : monday.clone().add(1 * num, 'weeks');
      endDate = startDate.clone().add(4, 'days');
      cellUnit = CellUnits.Hour;
    } else if (viewType === ViewTypes.Custom1) {
      startDate = schedulerData.localeMoment(selectDate);
      endDate = schedulerData.localeMoment(selectDate);
      cellUnit = CellUnits.Hour;
    } else if (viewType === ViewTypes.Custom2) {
      let firstDayOfMonth = schedulerData.localeMoment(selectDate).startOf('month').format(DATE_FORMAT);
      startDate =
        num === 0
          ? firstDayOfMonth
          : schedulerData
              .localeMoment(firstDayOfMonth)
              .add(1 * num, 'months')
              .format(DATE_FORMAT);
      endDate = schedulerData.localeMoment(startDate).endOf('month').format(DATE_FORMAT);
      cellUnit = CellUnits.Day;
    }
    return {
      startDate,
      endDate,
      cellUnit,
    };
  };

  /**
   * This function is called once for each header by package
   * Ex: 2024-11-01 06:00, 2024-11-01 06:30 etc.
   * We need to modify how the header render using this function
   * We have used margin and borders and css position to get desired header.
   */
  const renderDayHeader = (schedulerData, item) => {
    const date = moment(item.time).format('dddd, DD MMMM YYYY');
    const itemTime = moment(item.time, 'YYYY-MM-DD HH:mm:ss').format('HH:mm');
    const isStart = itemTime === (selectedViewType === ViewTypes.Custom1 ? DAY_VIEW_START : WEEK_VIEW_START);
    const isEnd = itemTime === (selectedViewType === ViewTypes.Custom1 ? DAY_VIEW_END : WEEK_VIEW_END);
    const isToday = moment(item.time).isSame(moment(), 'day');
    const customCellWidth = schedularConfig.customCellWidth;
    const currentTime = moment(itemTime, 'HH:mm');
    const nonWorkingStart = moment(NON_WORKING_HOURS_BEFORE, 'HH:mm');
    const nonWorkingEnd = moment(NON_WORKING_HOURS_AFTER, 'HH:mm');
    const nonWorking = currentTime.isBetween(nonWorkingStart, nonWorkingEnd, 'h', '[]');

    if (selectedViewType === ViewTypes.Custom2) {
      const startOfMonth = moment(item.time).startOf('month');
      const endOfMonth = moment(item.time).endOf('month');
      const isMonthStart = moment(item.time).isSame(startOfMonth, 'day');
      const startOfWeek = moment(item.time).clone().startOf('isoWeek');
      const isStartOfWeek = moment(item.time).isSame(startOfWeek, 'day');
      const weekNumber = moment(item.time).isoWeek();
      const currentWeekNumber = moment().isoWeek();
      return (
        <>
          <th
            className='header3-text'
            style={{
              width: customCellWidth,
              border: 'none',
              borderLeft: isMonthStart || isStartOfWeek ? '1px solid #e0e1dd' : 'none',
            }}
          >
            {(isMonthStart || isStartOfWeek) && (
              <div className={cn('month-week-text-display', { 'header-today-text': weekNumber === currentWeekNumber })}>
                <span>Week {weekNumber}</span>
              </div>
            )}
            <div
              className={cn('month-slot-header', 'month-view-border-seprator')}
              style={{ backgroundColor: isToday ? '#C9E0FF' : item?.nonWorkingTime ? '#DCE3E9' : '#fff' }}
            >
              <div>{moment(item.time).format('ddd')}</div>
              <div>{moment(item.time).format('DD.MM')}</div>
            </div>
          </th>
        </>
      );
    }
    return (
      <th
        className='header3-text'
        style={{
          width: customCellWidth * 2,
          border: 'none',
          borderLeft: isStart ? '1px solid #e0e1dd' : 'none',
        }}
      >
        {isStart && (
          <div className={cn('slot-header-date', { 'header-today-text': isToday })}>
            <span>{date}</span>
          </div>
        )}
        <div
          className='d-flex justify-content-center align-items-center header-week-view-time'
          style={{
            borderRight: !isEnd ? '1px solid #e0e1dd' : 'none',
            backgroundColor: nonWorking ? '#fff' : '#DCE3E9',
          }}
        >
          <span>{moment(item.time, 'YYYY-MM-DD HH:mm:ss').format('HH:mm')}</span>
        </div>
      </th>
    );
  };

  const prevClick = e => {
    if (selectedViewType === ViewTypes.Custom1) {
      setSelectedDate(date => moment(date).subtract(1, 'day').format(DATE_FORMAT));
      return;
    }
    if (selectedViewType === ViewTypes.Custom2) {
      setSelectedDate(date => moment(date).subtract(1, 'month').startOf('month').format(DATE_FORMAT));
      return;
    }
    setSelectedDate(date => moment(date).subtract(1, 'week').startOf('week').format(DATE_FORMAT));
  };

  const nextClick = e => {
    if (selectedViewType === ViewTypes.Custom1) {
      setSelectedDate(date => moment(date).add(1, 'day').format(DATE_FORMAT));
      return;
    }
    if (selectedViewType === ViewTypes.Custom2) {
      setSelectedDate(date => moment(date).add(1, 'month').startOf('month').format(DATE_FORMAT));
      return;
    }
    setSelectedDate(date => moment(date).add(1, 'week').startOf('week').format(DATE_FORMAT));
  };

  const todayClick = e => {
    setSelectedDate(moment().format(DATE_FORMAT));
  };

  const onSelectDate = date => {
    if (selectedViewType === ViewTypes.Custom1) {
      setSelectedDate(moment(date).format(DATE_FORMAT));
      return;
    }
    setSelectedDate(moment(date).format(DATE_FORMAT));
  };

  const onViewChange = viewType => {
    setSelectedViewType(viewType);
    setCalendarData(viewType);
  };

  const newEvent = (schedulerData, slotId, slotName, start, end, type, item) => {
    const notCreateEvents = ['comments', 'systems', 'equipment', 'other', 'person', '__comments','__'];
    for (const notCreateEvent of notCreateEvents) {
      if (slotId.includes(notCreateEvent)) {
        return;
      }
    }

    if (moment(start).isBefore(moment())) {
      scrollToTop(500);
       toggleActionMessage(true, 'error', 'pastDateBooking');
       return;
    }
    let createSlotId = schedulerData.resources.find((resource) => resource.id === slotId);
    let selectedSystemEvents = [];

    // Generate a new unique ID for the event
    let newFreshId = 1;
    schedulerData.events.forEach((item) => {
      if (item.id >= newFreshId)
        newFreshId = item.id + 1;
    });
    if (selectedSystem.length > 0) {
      const filteredSelectedEvents = filteredEvents(schedulerData);
      selectedSystem.forEach(element => {
        let newEvent = {
          id: newFreshId,
          title: '',
          start: start,
          end: end,
          resourceId: `${element}`,
          isNewEvent: true,
          systemData: schedulerData.resources.find((resource) => resource.id === element)
        };
        schedulerData.addEvent(newEvent)
      });
    }else{
      let newEvent = {
        id: newFreshId,
        title: '',
        start: start,
        end: end,
        resourceId: `${slotId}`,
        isNewEvent: true,
        systemData: createSlotId
      };
      // const updatedSchedulerData = { ...schedulerData, events: [...schedulerData.events, newEvent] };
      schedulerData.addEvent(newEvent);
    }

    const clonedData = cloneDeep(schedulerData);
    setcalendarConfig(clonedData);

  };

  const filteredEvents = (schedulerData) => {
    const filteredEvents = schedulerData?.events.filter(event => selectedSystem.includes(event.resourceId));
    return filteredEvents;

  }

 const updateEventStart = (schedulerData, event, newStart) => {
  if (selectedSystem.length > 0) {
    const filteredSelectedEvents = filteredEvents(schedulerData);
    filteredSelectedEvents.forEach(event => {
      schedulerData.updateEventStart(event, newStart);
    });
  }else{
    schedulerData.updateEventStart(event, newStart);
  }
  const clonedData = cloneDeep(schedulerData);
  setcalendarConfig(clonedData);
}

const updateEventEnd = (schedulerData, event, newEnd) => {
  if (selectedSystem.length > 0) {
    const filteredSelectedEvents = filteredEvents(schedulerData);
    filteredSelectedEvents.forEach(event => {
      schedulerData.updateEventEnd(event, newEnd);
    });
  }else{
    schedulerData.updateEventEnd(event, newEnd);}
    const clonedData = cloneDeep(schedulerData);
    setcalendarConfig(clonedData);
}

const moveEvent = (schedulerData, event, slotId, slotName, start, end) => {
  if (selectedSystem.length > 0) {
    const filteredSelectedEvents = filteredEvents(schedulerData);
    // filteredSelectedEvents.forEach(event => {
    //   schedulerData.moveEvent(event, slotId, slotName, start, end);
    // });
  }else{
    schedulerData.moveEvent(event, slotId, slotName, start, end);
  }
  const clonedData = cloneDeep(schedulerData);
  setcalendarConfig(clonedData);
}

  const eventClicked = (schedulerData, event) => {
    setSingleEventClickedId(event.id);
    if (event.isNewEvent) {
      setEventSelectedPopupOpen(true);
    $(`#confirm-booking-modal`).modal('show');
    }
  };

  const onRemoveEvent = (schedulerData, event) => {
    schedulerData.removeEvent(event);
    const clonedData = cloneDeep(schedulerData);
    setcalendarConfig(clonedData);

    const resourceId = event?.resourceId;
    setSelectedSystem((prevValues) => {
    if (prevValues.includes(resourceId)) {
      // Remove the resourceId if it exists in the selectedSystem array
      return prevValues.filter((item) => item !== resourceId);
    }
    return prevValues;
  });
  }

  const onChangeCheckbox = (e, schedulerData) => { 
    const value = e;
    const event = schedulerData?.events?.find(event => event.isNewEvent && event.resourceId === value);
    const alreadySelectedEvent = schedulerData?.events?.find(event => event.isNewEvent);
    const createSlotId = schedulerData.resources.find((resource) => resource.id === value);
    setSelectedSystem((prevValues) => {
      if (prevValues.includes(value)) {
        // Remove the value if it exists
        onRemoveEvent(schedulerData, event);
        return prevValues.filter((item) => item !== value);
      } else {
        // Add the value if it does not exist
        return [...prevValues, value];
      }
    });
    // if ((selectedSystem.length > 0 && alreadySelectedEvent && !selectedSystem.includes(value) && (event === undefined || event === null)) || alreadySelectedEvent && !event ) {
    //   let newEvent = {
    //     id: alreadySelectedEvent.id,
    //     title: '',
    //     start: alreadySelectedEvent.start,
    //     end: alreadySelectedEvent.end,
    //     resourceId: `${value}`,
    //     isNewEvent: true,
    //     systemData: createSlotId
    //   };
  
    //   schedulerData.addEvent(newEvent);
    //   const clonedData = cloneDeep(schedulerData);
    //   setcalendarConfig(clonedData);
    // }
  };

  const slotTempletResolver = (schedulerData, slot, slotClickedFunc, width, clsName) => {
    return (
      <ResourceItem
        schedulerData={schedulerData}
        slot={slot}
        hiddenResourceGroups={hiddenResourceGroups}
        toggleExpandFunc={toggleExpandFunc}
        onChangeCheckbox={(e, schedulerData) => onChangeCheckbox(e, schedulerData)}
        selectedSystem={selectedSystem}
      />
    );
  };

  const hideRecursively = (resources, parentId) => {
    let itemsToHide = [parentId];
    resources.forEach(resource => {
      if (resource.parentId === parentId) {
        itemsToHide = [...itemsToHide, ...hideRecursively(resources, resource.id)];
      }
    });
    return itemsToHide;
  };

  /**
   * Callback function to toggle the expansion state of resource groups in the scheduler.
   *
   * This function takes an array of `slotIds` and updates the `hiddenResourceGroups` state
   * to reflect the new expansion/collapse state of the corresponding resource groups.
   * It uses a `Set` for efficient management of hidden resource IDs.
   *
   * @param {SchedulerData} schedularData - The scheduler data object.
   * @param {string[]} slotIds - An array of slot IDs to toggle.
   *
   * enhance: When there is huge and lagging in toggling groups, instead setting the resources to Schedular use
   * `ResourceItem` to return null to avoid re-rendering and building the schedular data
   */
  const toggleExpandFunc = useCallback(
    (schedularData, slotIds) => {
      const { resources } = schedularData;
      const hiddenSet = new Set(hiddenResourceGroups);
      slotIds.forEach(slotId => {
        const resourcesToHide = hideRecursively(resources, slotId);
        if (hiddenSet.has(slotId)) {
          hiddenSet.delete(slotId);
          resourcesToHide.forEach(resourceId => hiddenSet.delete(resourceId));
        } else {
          hiddenSet.add(slotId);
          resourcesToHide.forEach(resourceId => hiddenSet.add(resourceId));
        }
      });
      setHiddenResourceGroups([...hiddenSet]);
      setCalendarData(selectedViewType);
    },
    [hiddenResourceGroups, setHiddenResourceGroups, setCalendarData],
  );

  const PopoverItem = ({ eventItem }) => {
    const { type, commentOnly, title, description, start, end, bookingCategoryTranslated } = eventItem;
    if (eventItem?.isNewEvent) {
      return (
        <div>Click on "+" to create booking</div>
      );
    }
    const stockId = eventItem?.stockId;
    let bgColor = type === 'type1' ? '#000000' : type === 'type2' ? '#CF3652' : '#F4B084';
    const formattedStart = moment(start).format('YYYY-MM-DD HH:mm');
    const formattedEnd = moment(end).format('YYYY-MM-DD HH:mm');
    let commentEditAccess = user?.admin;
    if(stockId && !user.admin) {
      commentEditAccess = checkStockRoles(["Dispatcher", "Stock Controller"], user, {id: parseInt(stockId)})
    }
    if (commentOnly) {
      return (
        <>
          <p style={{ fontSize: 14, fontWeight: 'bold' }} className='mb-2'>
            {title}
          </p>
          <b>Duration:</b>
          <p>{`${formattedStart} to ${formattedEnd}`}</p>
          <b>Event Information:</b>
          <p>
            {description}
          </p>
          <div className='d-flex align-items-center justify-content-end mt-3'>
            {commentEditAccess && <button className='mt-3 align-self-end edit-button' onClick={() => onAddCommnetClick(eventItem)}>Edit</button>}
          </div>
        </>
      );
    }

    return (
      <>
        <div className='d-flex align-items-center event-popover-container mb-2'>
          <div className='event-dot align-self-start' style={{ backgroundColor: bgColor }}></div>
          <div>
            <div>{`${bookingCategoryTranslated} ${moment(start).format('HH:mm')} - ${moment(end).format('HH:mm')}`}</div>
          </div>
        </div>
      </>
    );
  };

  const slotItemPopover = (schedulerData, eventItem, title, start, end, statusColor) => {
    return <PopoverItem eventItem={eventItem} />;
  };

  const onAddCommnetClick = (eventItem) => {
    setSelectedCommentEvent(eventItem);
    $(`#add-location-comment-modal`).modal('show');
  }

 const conflictOccurred = (schedulerData, action, event, type, slotId, slotName, start, end) => {
  scrollToTop(500);
  toggleActionMessage(true, 'error', 'conflictBooking');
   return
}


  return {
    calendarConfig,
    prevClick,
    nextClick,
    onSelectDate,
    onViewChange,
    eventClicked,
    renderDayHeader,
    selectedViewType,
    slotTempletResolver,
    toggleExpandFunc,
    schedulerRef,
    todayClick,
    selectedDate,
    slotItemPopover,
    iqsCalendarResources,
    onAddCommnetClick,
    selectedCommentEvent,
    setSelectedCommentEvent,
    newEvent,
    updateEventStart,
    updateEventEnd,
    moveEvent,
    onRemoveEvent,
    selectedSystem,
    eventSelectedPopupOpen,
    setEventSelectedPopupOpen,
    conflictOccurred,
    setSelectedSystem,
    user,
    singleEventClickedId,
  };
};
