import Validators from 'library/utilities/Validators';

export const customerContactPopupModel = [
  {
    label: 'crmIdAvailable',
    value: '',
    type: 'enhanced-circle-RadioButton',
    placeholder: '',
    field: 'crmIdAvailable',
    validators: [],
    required: false,
    styleClass: 'col-6',
    options: [
      {
        id: 'YES',
        value: 'yes',
      },
      {
        id: 'NO',
        value: 'no',
      },
    ],
  },
  {
    label: 'custromerCrmId',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'custromerCrmId',
    validators: [],
    required: false,
    styleClass: 'col-6',
    disabled: false,
  },
  {
    label: 'salutation',
    value: '',
    type: 'dropdown',
    placeholder: 'pleaseSelect',
    field: 'salutation',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-6',
    options: [
      {
        id: 'MR',
        value: 'mr',
      },
      {
        id: 'MRS',
        value: 'mrs',
      },
      {
        id: 'MS',
        value: 'ms',
      },
    ],
    hasSection: false,
    multiSelect: false,
    filter: true,
    idKey: 'id',
    displayValue: [{ key: 'value', separator: ' ' }],
    showClearIcon: false,
  },
  {
    label: 'firstName',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'firstName',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-6',
    disabled: false,
  },
  {
    label: 'lastName',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'lastName',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-6',
    disabled: false,
  },
  {
    label: 'department',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'department',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-6',
    disabled: false,
  },
  {
    label: 'function',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'function',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-6',
    disabled: false,
  },
  {
    label: 'phone',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'phone',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-6',
    disabled: false,
  },
  {
    label: 'emailColHeader',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'email',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-6',
    disabled: false,
  },
  {
    label: 'defaultRole',
    value: '',
    type: 'dropdown',
    placeholder: 'pleaseSelect',
    field: 'defaultRole',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-6',
    options: [],
    hasSection: false,
    multiSelect: false,
    filter: true,
    idKey: 'id',
    displayValue: [{ key: 'name', separator: ' ' }],
    showClearIcon: false,
  },
  {
    label: 'contactCompanySapId',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'contactCompanySapId',
    validators: [],
    required: false,
    styleClass: 'col-6',
    disabled: false,
  },
  {
    label: 'commentText',
    value: '',
    type: 'textarea',
    placeholder: '',
    field: 'commentText',
    validators: [],
    required: false,
    styleClass: 'col-12',
    disabled: false,
  },
];
