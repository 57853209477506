import React from 'react';
import Modal from 'library/common/commonComponents/Modal';
import strings from 'resources/locales/Translate';
import FormComponent from 'library/common/commonComponents/FormComponent/Optimized';
import { useCompanyPopup } from './CompanyPopup.hook';
import { connect } from 'react-redux';

export const CompanyPopup = ({ id, allDropdownData }) => {
  const { getcompanyPopupModel } = useCompanyPopup({ id, allDropdownData });
  return (
    <div>
      <div>
        <Modal
          id={id}
          title={strings.company}
          //   confirmAction={onSaveClick}
          confirmTitle={strings.confirm}
          //   loading={loading}
          //   onCloseClick={onCloseClick}
          className='add-location-comment-popup-width'
          newVersionUi={true}
          titleOfCancel={strings.cancel}
        >
          <div className='mt-4'>
            <FormComponent
              formName='addLocationCommentForm'
              model={getcompanyPopupModel()}
              // formValue={locationCommentFormData.formData}
              // onChange={handleForm}
            />
          </div>

          {/* {error && <span className='text-danger error-block'>{error}</span>} */}
        </Modal>
      </div>
    </div>
  );
};

const mapStateToProps = ({ authReducer, iqsRequestReducer }) => ({
  user: authReducer.user,
  allDropdownData: iqsRequestReducer.allDropdownData,
});

export default connect(mapStateToProps, {})(CompanyPopup);
