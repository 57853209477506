// utilise this file if we have any enums/objects that commonly used accross application

export const timeSlotList = [
  { id: '08:00', value: '8:00 AM' },
  { id: '08:30', value: '8:30 AM' },
  { id: '09:00', value: '9:00 AM' },
  { id: '09:30', value: '9:30 AM' },
  { id: '10:00', value: '10:00 AM' },
  { id: '10:30', value: '10:30 AM' },
  { id: '11:00', value: '11:00 AM' },
  { id: '11:30', value: '11:30 AM' },
  { id: '12:00', value: '12:00 PM' },
  { id: '12:30', value: '12:30 PM' },
  { id: '13:00', value: '1:00 PM' },
  { id: '13:30', value: '1:30 PM' },
  { id: '14:00', value: '2:00 PM' },
  { id: '14:30', value: '2:30 PM' },
  { id: '15:00', value: '3:00 PM' },
  { id: '15:30', value: '3:30 PM' },
  { id: '16:00', value: '4:00 PM' },
  { id: '16:30', value: '4:30 PM' },
  { id: '17:00', value: '5:00 PM' },
  { id: '17:30', value: '5:30 PM' },
  { id: '18:00', value: '6:00 PM' },
];
export const sapCrmNewCient = 'sapcrm020';
export const IQS_SSC_DE_DESIGNATION = 'IQS_SSC_DE';
export const RMS_L_L_DESIGNATION = 'RMS_L_L';
export const RMS_ZMCC_DESIGNATION = 'RMS_ZMCC';

export const REQUEST = 'ZMCC_REQUEST';
export const CHECKED = 'ZMCC_CHECKED';
export const CANCELLED = 'ZMCC_CANCELLED';
export const WAITING_FOR_SAMPLES = 'ZMCC_WAITING_FOR_SAMPLES';
export const WAITING_FOR_PROCESSING = 'ZMCC_WAITING_FOR_PROCESSING';
export const IN_PROGRESS = 'ZMCC_IN_PROGRESS';
export const WAITING_FOR_REPORT = 'ZMCC_WAITING_FOR_REPORT';

// Utility function to get constant by parameter
export function getStatusConstantByKey(key) {
  const statusMap = {
    REQUEST,
    CHECKED,
    CANCELLED,
    WAITING_FOR_SAMPLES,
    WAITING_FOR_PROCESSING,
    IN_PROGRESS,
    WAITING_FOR_REPORT,
  };

  return statusMap[key] || 'Unknown status';
}

export const IQS_SSC_DC_STOCK_ALLOWED_FILE_TYPES = 'image/*,.pdf,.ppt,.pptx,.jpg,.png,.jpeg,.xls,.xlsx,.doc,.docx';

export const RESOURCE_TYPE_SYSTEM = 'SYSTEM';

export const STOCK_CONTROLLER = 'STOCK_CONTROLLER';
export const DISPATCHER = 'DISPATCHER';
export const ZMCC_OPERATOR = 'ZMCC_OPERATOR';
export const BORROWER = 'BORROWER';
export const READ_ONLY_USER = 'READ_ONLY_USER';
export const ZMCC_EVENT_DEFAULTS = {
  FROM_TIME: '08:00 AM',
  TO_TIME: '06:00 PM',
};

export const BUSINESS_UNIT_NAMES = Object.freeze({
  IQS_SSC_DE: "IQS (SSC DE)"
});

export const IQS_BUSINESS_UNIT_ID = 11;
export const itemsPerPage = {
  10: { id: 10, pages: 10 },
  20: { id: 20, pages: 20 },
  50: { id: 50, pages: 50 },
};

export const kitCompoennetUseStatusOptions =[
  {
    id: 'Active',
    value: 'ACTV',
  },
  {
    id: 'InActive',
    value: 'DACT',
  },
]

export const marketSegmentList = [
  {
      "id": 1,
      "name": "Life Sciences",
      "staticCode": "M11"
  },
  {
      "id": 2,
      "name": "Education & Routine",
      "staticCode": "M13"
  },
  {
      "id": 6,
      "name": "Materials Science",
      "staticCode": "M14"
  },
  {
      "id": 3,
      "name": "Raw Materials",
      "staticCode": "M15"
  },
  {
      "id": 5,
      "name": "Electronics (IQS & RMS)",
      "staticCode": "M16"
  },
  {
      "id": 4,
      "name": "IQS Ind Microscopy",
      "staticCode": " M17"
  }
];

export const marketSubSegmentList = [
  {
      "id": 1,
      "name": "Electronics & Semiconductor",
      "staticCode": "00035"
  },
  {
      "id": 2,
      "name": "Aerospace",
      "staticCode": "00037"
  },
  {
      "id": 3,
      "name": "Automotive",
      "staticCode": "00034"
  },
  {
      "id": 4,
      "name": "Manufacturing & Machining (incl. Medical)",
      "staticCode": "00038"
  },
  {
      "id": 5,
      "name": "Materials Research",
      "staticCode": "00039"
  },
  {
      "id": 6,
      "name": "Metals & Steel",
      "staticCode": "00033"
  },
  {
      "id": 7,
      "name": "Mining & Geosciences",
      "staticCode": "00031"
  },
  {
      "id": 8,
      "name": "Oil & Gas",
      "staticCode": "00029"
  },
  {
      "id": 9,
      "name": "Clinical / Medical",
      "staticCode": "00040"
  },
  {
      "id": 10,
      "name": "Education & Teaching",
      "staticCode": "00019"
  },
  {
      "id": 11,
      "name": "Food & Beverages",
      "staticCode": "00023"
  },
  {
      "id": 12,
      "name": "Forensics",
      "staticCode": "00022"
  },
  {
      "id": 13,
      "name": "Life Science Routine",
      "staticCode": "00020"
  },
  {
      "id": 14,
      "name": "Life Science Research",
      "staticCode": "00039"
  }
]


export const PERFORMANCE9PRODUCTIONOFSAMPLES = "PERFORMANCE9:PRODUCTIONOFSAMPLES";
export const PERFORMANCE12WORKSHOPSCOURSESTRAININGS = "PERFORMANCE12:WORKSHOPS,COURSES,TRAININGS";
export const TIMEINHOURS = "TIMEINHOURS";
export const AGREEDPOINTS = "AGREEDPOINTS";
