export const identifiers = {
  token: 'token',
  access_token : 'access_token',
  access_token_expiry:'access_token_expiry',
  login_session_expiry:'login_session_expiry',
  login_id:'login_id',
  language: 'language',
  path: 'path',
  cart: 'cart',
  homepageFilter: 'homepageFilter',
  user: 'user',
  openLogin: 'openLogin',
  filtersTransaction: 'filtersTransaction',
  filtersTransactionArchived: 'filtersTransactionArchived',
  filtersTransactionCancelled: 'filtersTransactionCancelled',
  filtersKit: 'filtersKit',
  filtersTransactionSort: 'filtersTransactionSort',
  filtersTransactionSortArchive: 'filtersTransactionSortArchive',
  filtersTransactionSortCancelled: 'filtersTransactionSortCancelled',
  filtersCalendar: 'filtersCalendar',
  filtersStock: 'filtersStock',
  filtersTransactionKitApproval: 'filtersTransactionKitApproval',
  filtersTransactionSortKitApproval: 'filtersTransactionSortKitApproval',
  crmParams: 'crmParams',
  labslocationCrmParams: 'labslocationCrmParams',
  filtersLabsLocationCalendar: 'filtersLabsLocationCalendar',
  filtersLabsLocationTransaction: 'filtersLabsLocationTransaction',
  identifierLabsLocationSort: 'identifierLabsLocationSort',
  filtersLabsLocationTransactionCancelled: 'filtersLabsLocationTransactionCancelled',
  identifierLabsLocationSortCancelled: 'identifierLabsLocationSortCancelled',
  filtersLabsLocationTransactionArchive: 'filtersLabsLocationTransactionArchive',
  identifierLabsLocationSortArchive: 'identifierLabsLocationSortArchive',
  dla:'dla',
  labsAtLocation:'labsAtLocation',
  sessionExpiryDate:'sessionExpiryDate',
  hitUrl:'hitUrl',
  stockPopup: 'stockPopup',
  userAddress: 'userAddress',
  zmcc: 'zmccListFilter',
  filtersCancelledApproval: 'filtersCancelledApproval',
  showBorrowerInfoMessage: 'showBorrowerInfoMessage',
  zmccSubLoanMenus: 'zmccSubLoanMenus',
  removeAvailibilityForZmcc: 'removeAvailibilityForZmcc',
  zmccCrmParams: 'zmccCrmParams',
  showCrmPopup: 'showCrmPopup',
  zmccFilters:'zmccFilters',
  zmccTransactionListFilter: 'zmccTransactionListFilter',
  isZmccTransactionsCheckboxActive: 'isZmccTransactionsCheckboxActive',
  shouldShowLoginPopup:'shouldShowLoginPopup',
  lastSlectedCheckboxInTrans: 'lastSlectedCheckboxInTrans',
  lastSlectedCheckboxInKits: 'lastSlectedCheckboxInKits',
  kitAddSoltPopup: 'kitAddSoltPopup',
  editLogisticInputValue: 'editLogisticInputValue',
  editLogisticCheckboxValue: 'editLogisticCheckboxValue',
  zmccHomePageFilters: 'zmccHomePageFilters',
  zmccDrfTabId: 'zmccDrfTabId',
  urlChangeNotificationShown: 'urlChangeNotificationShown',
  cancelledZmccFilters: 'cancelledZmccFilters',
  archivedZmccFilters: 'archivedZmccFilters',
  showWelcomePopup: 'showWelcomePopup',
  iqsCalendarFilters: 'iqsCalendarFilters',
};
