import React, { useEffect, useState } from 'react';
import $ from 'jquery';
import strings from 'resources/locales/Translate';
import FormComponent from 'library/common/commonComponents/FormComponent/Optimized';
import Modal from 'library/common/commonComponents/Modal';
import { checkUserRoles } from 'library/utilities/checkRoles';
import { validateForm } from 'library/utilities/ValidateForm.util';
import { permissions } from 'library/common/commonConstants/permissionsConstants';
import { exportKitModel } from './ExportFilterKits.constants';
import { fetchMasterDataPost } from 'library/common/commonActions/MasterDataActions';
import { URLS } from 'library/common/commonConstants/ApiUrlConstants'
import * as actionTypes from 'library/common/commonConstants/storeConstants';
export const ExportFilterKits = ({
  categoriesAndSystems,
  conditions,
  countries,
  exportKitList,
  kitStatus,
  id,
  filters,
  user,
  changeLoader,
  fetchStockInformationIds,
  fetchWarehouseBySystemClass,
  fetchMasterData
}) => {
  const [loading, setLoading] = useState(false);
  const [exportKitForm, setExportKitForm] = useState({ formData: {}, isFormValid: false });
  const hasNoStockRestrictions = checkUserRoles(permissions.stockRestrictions, user);
  const hasCategory = exportKitForm.formData.category && exportKitForm.formData.category.length;
  const hasStocksMoreThanThree = exportKitForm.formData.stock && exportKitForm.formData.stock.length > 20;
  const hasMaxNumberOfStocks =  hasStocksMoreThanThree;
  const hasError = hasStocksMoreThanThree ? 'maxLengthErrorMessage' : null;
  useEffect(() => {
    if (categoriesAndSystems && categoriesAndSystems.length) {
        setExportKitForm(prevState => ({
          ...prevState,
          formData: {
            ...prevState.formData,
            category: prevState.formData.category && prevState.formData.category.length>0 ? prevState.formData.category : filters.category,
            categoryFullValue: prevState.formData.categoryFullValue && prevState.formData.categoryFullValue.length>0 ? prevState.formData.categoryFullValue :filters.categoryFullValue ? filters.categoryFullValue : [],
            categoryError: null,
          },
        }));
    }
  }, [categoriesAndSystems, filters.category, filters.categoryFullValue]);
  useEffect(() => {
    if(countries && countries.length==0){
      setExportKitForm(prevState => ({
        ...prevState,
        formData: {
          ...prevState.formData,
          stock: [],
          stockFullValue: [],
          stockError: hasError,
        },
      }));
      return;
    }
    if (countries && countries.length) {
      if(filters.stock==null || filters.stockFullValue==null){
        setExportKitForm(prevState => ({
          ...prevState,
          formData: {
            ...prevState.formData,
            stock: null,
            stockFullValue: null,
            stockError: hasError,
          },
        }));
      } else{
        setExportKitForm(prevState => ({
          ...prevState,
          formData: {
            ...prevState.formData,
            stock: prevState.formData.stock && prevState.formData.stock.length>0 ? prevState.formData.stock : filters.stock,
            stockFullValue: prevState.formData.stockFullValue && prevState.formData.stockFullValue.length>0 ? prevState.formData.stockFullValue : filters.stockFullValue ? filters.stockFullValue : [],
            stockError: hasError,
          },
        }));
      }
    }
  }, [countries, filters.stock, filters.stockFullValue]);
  useEffect(() => {
    if (conditions && conditions.length) {
      setExportKitForm(prevState => ({
        ...prevState,
        formData: {
          ...prevState.formData,
          condition: filters.condition,
          conditionFullValue: filters.conditionFullValue,
          conditionError: null,
        },
      }));
    }
  }, [conditions, filters.condition, filters.conditionFullValue]);
  useEffect(() => {
    if (kitStatus && kitStatus.length) {
      setExportKitForm(prevState => ({
        ...prevState,
        formData: {
          ...prevState.formData,
          status: filters.status,
          statusFullValue: filters.statusFullValue,
          statusError: null,
        },
      }));
    }
  }, [kitStatus, filters.status, filters.statusFullValue]);
  const onCloseClick = async() => {
    $(`#${id}`).modal('hide');
    await fetchMasterData(URLS.systemClassWithStock, actionTypes.FETCH_SYSTEM_CLASS_WITH_STOCKS);
    await fetchMasterData(`${URLS.countryList}?loantype=kit-loan`, actionTypes.FETCH_COUNTRY_LIST);
    setExportKitForm({
      ...exportKitForm,
      formData: {
        stock: filters.stock,
        stockFullValue: filters.stockFullValue,
        stockError: hasError,
        category: filters.category,
        categoryFullValue: filters.categoryFullValue,
        categoryError: null,
        condition: filters.condition,
        conditionFullValue: filters.conditionFullValue,
        conditionError: null,
        status: filters.status,
        statusFullValue: filters.statusFullValue,
        statusError: null,
      },
    });
  };
  const onSaveClick = async () => {
    const validForm = validateForm({
      form: exportKitForm,
      model: exportKitModel({ categoriesAndSystems, conditions, countries, kitStatus, hasMaxNumberOfStocks }),
    });
    if (!validForm.isFormValid) {
      setExportKitForm(validForm);
    } else {
      setLoading(true);
      changeLoader(true);
      onCloseClick();
      openContinueUseModal();
      await exportKitList(validForm.formData);
      closeContinueUseModal();
      setLoading(false);
    }
  };
  const openContinueUseModal = () =>{
    $('#continue-use-app').modal('show');
  }
  const closeContinueUseModal = () =>{
    $(`#continue-use-app`).modal('hide');
    changeLoader(false)
}
  const handleForm = async ({ field, values }) => {
    if (field === 'stock') {
      if (values.stock.length === 0) {
        await fetchMasterData(URLS.systemClassWithStock, actionTypes.FETCH_SYSTEM_CLASS_WITH_STOCKS);
      }else{
        fetchWarehouseBySystemClass({stockIds: values.stock})
      }
      const exceedsMaxNumberOfStocks = values.stock && values.stock.length > 20;
      const stockError = exceedsMaxNumberOfStocks  ? 'maxLengthErrorMessage' : null;
      return setExportKitForm({
        ...exportKitForm,
        formData: {
          ...exportKitForm.formData,
          ...values,
          stockError,
        },
      });
    }
    if (field === 'category') {
      if (values.category.length === 0) {
        await fetchMasterData(`${URLS.countryList}?loantype=kit-loan`, actionTypes.FETCH_COUNTRY_LIST);
      }else{
        fetchStockInformationIds({systemClassIds:values.category});
      }
      const stockError = hasStocksMoreThanThree ? 'maxLengthErrorMessage' : null;
      return setExportKitForm({
        ...exportKitForm,
        formData: {
          ...exportKitForm.formData,
          ...values,
        },
      });
    }
    return setExportKitForm({
      ...exportKitForm,
      formData: {
        ...exportKitForm.formData,
        ...values,
        stockError: hasError,
      },
    });
  };
  return (
    <Modal
      id={id}
      className=''
      title={strings.exportList}
      confirmAction={onSaveClick}
      confirmDataTest='export-filter-kits-button-save'
      confirmTitle={strings.save}
      loading={loading}
      disableBackgroundClose
      onCloseClick={onCloseClick}
    >
      <FormComponent
        model={exportKitModel({categoriesAndSystems, conditions, countries, kitStatus, hasMaxNumberOfStocks,fetchMasterDataPost })}
        formName='exportKitForm'
        formValue={exportKitForm.formData}
        onChange={handleForm}
      />
    </Modal>
  );
};
export default ExportFilterKits;