import { FETCH_ALLIQS_REQUEST_DROPDOWN_DATA } from './IqsNewRequest.constants';

const initialState = {
  allDropdownData: {},
};
const IqsRequestReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ALLIQS_REQUEST_DROPDOWN_DATA:
      return {
        ...state,
        allDropdownData: action.payload,
      };
    default:
      return state;
  }
};

export default IqsRequestReducer;
