import Validators from 'library/utilities/Validators';

export const confirmBookingInformationModel = [
  {
    label: 'bookingType',
    value: '',
    type: 'dropdown',
    placeholder: 'pleaseSelect',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    field: 'bookingType',
    required: true,
    styleClass: 'col-6',
    options: [],
    hasSection: false,
    multiSelect: false,
    filter: true,
    idKey: 'staticCode',
    displayValue: [{ key: 'name', separator: '' }],
  },
  {
    label: 'bookingCategory',
    value: '',
    type: 'dropdown',
    placeholder: 'pleaseSelect',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    field: 'bookingCategory',
    required: true,
    styleClass: 'col-6',
    options: [],
    hasSection: false,
    multiSelect: false,
    filter: true,
    idKey: 'staticCode',
    displayValue: [{ key: 'name', separator: '' }],
  },
  {
    label: 'beginStart',
    value: '',
    type: 'dateAndTimePicker',
    placeholder: 'pleaseSelect',
    field: 'bookingFrom',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-6',
    dateFormat: 'DD.MM.YYYY',
    minDate: new Date(),
  },
  {
    label: 'end',
    value: '',
    type: 'dateAndTimePicker',
    placeholder: 'pleaseSelect',
    field: 'bookingTo',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-6',
    dateFormat: 'DD.MM.YYYY',
    minDate: new Date(),
  },
];

export const confirmBookingRequestModel = [
  {
    label: 'relatedRequest',
    value: '',
    type: 'dropdown',
    placeholder: 'pleaseSelect',
    validators: [],
    required: true,
    field: 'relatedRequest',
    required: false,
    styleClass: 'col-6',
    options: [],
    hasSection: false,
    multiSelect: false,
    filter: true,
    idKey: 'id',
    displayValue: [{ key: 'name', separator: '' }],
  },
  {
    label: 'relatedPosition',
    value: '',
    type: 'dropdown',
    placeholder: 'pleaseSelect',
    validators: [],
    required: true,
    field: 'relatedPosition',
    required: false,
    styleClass: 'col-6',
    options: [],
    hasSection: false,
    multiSelect: false,
    filter: true,
    idKey: 'id',
    displayValue: [{ key: 'name', separator: '' }],
  },
];

export const confirmBookingOtherModel = [
  {
    label: 'scheduleInputTitle',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'otherBookingTitle',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-12',
  },
  {
    label: 'descriptionText',
    value: '',
    type: 'textarea',
    placeholder: '',
    field: 'otherBookingDescription',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-12',
  },
];
