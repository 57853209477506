import React from 'react';
import { connect } from 'react-redux';
import Modal from 'library/common/commonComponents/Modal';
import Section from 'library/common/commonComponents/Section/Section';
import strings from 'resources/locales/Translate';
import FormComponent from 'library/common/commonComponents/FormComponent/Optimized';
import { useConfirmBookingPopup } from './ComfirmBookingPopup.hook';
import { createIqsRequest, fetchIqsBookingDropdownData } from '../../IQSCalendar.actions';

export const ComfirmBookingPopup = ({
  id,
  fetchIqsBookingDropdownData,
  iqsBookingDropdownData,
  calendarConfig,
  createIqsRequest,
  selectedSystem,
  eventSelectedPopupOpen,
  setEventSelectedPopupOpen,
  setSelectedSystem,
  language,
  singleEventClickedId
}) => {
  const {
    confirmBookingForm,
    informationModel,
    otherModel,
    requestModel,
    handleForm,
    onCloseClick,
    onSaveClick,
    error,
    currentSlectedSystem,
    loading,
  } = useConfirmBookingPopup({
    id,
    fetchIqsBookingDropdownData,
    iqsBookingDropdownData,
    calendarConfig,
    createIqsRequest,
    selectedSystem,
    eventSelectedPopupOpen,
    setEventSelectedPopupOpen,
    setSelectedSystem,
    language,
    singleEventClickedId
  });

  return (
    <Modal
      id={id}
      title={strings.confirmBooking}
      confirmAction={onSaveClick}
      confirmTitle={strings.confirm}
      loading={loading}
      onCloseClick={onCloseClick}
      className='add-location-comment-popup-width'
      newVersionUi={true}
      titleOfCancel={strings.cancel}
    >
      <div className='col-12 p-0'>
        <Section sectionTitle={strings.information}>
          <div className='mt-3'>
            <FormComponent
              model={informationModel()}
              formName='informationForm'
              formValue={confirmBookingForm.formData}
              onChange={handleForm}
            />
          </div>
        </Section>
      </div>
      <div className='col-12 mt-4 p-0'>
        <Section sectionTitle={strings.information}>
          <div className='mt-3'>
            <FormComponent
              model={requestModel()}
              formName='requestForm'
              formValue={confirmBookingForm.formData}
              onChange={handleForm}
            />
          </div>
        </Section>
      </div>
      <div lassName='col-12 mt-4 p-0'>
      <Section sectionTitle={strings.resources}>
      <div className='mt-3'>
      {currentSlectedSystem?.map((event, index) => {
        const { systemData } = event;
        return (
          <div key={index} style={{ marginBottom: "20px" }}>
            <div className='selected-resource' style={{ fontWeight: "700"}}>
              {systemData.kitName || systemData.name}
            </div>
            <div className='selected-resource' style={{fontWeight:'400'}}>
              {systemData.type === "EQUIPMENT" ? "Equipment" : systemData.type === "SYSTEM" ? "System" :systemData.type === "OTHERS" ? "Others" :  "Person"}, {systemData.name}
            </div>
            {systemData.type !== "PERSON" && <div className='selected-resource' style={{ fontWeight:'400' }}>{systemData.locationName}, {systemData.roomName}</div>}
            {systemData.type === "PERSON" && <div className='selected-resource' style={{ fontWeight:'400' }}>{systemData.locationName}</div>}
          </div>
        );
      })}
    </div>
        </Section>
      </div>
      <div className='col-12 mt-4 p-0'>
        <Section sectionTitle={strings.information}>
          <div className='mt-3'>
            <FormComponent
              model={otherModel()}
              formName='otherForm'
              formValue={confirmBookingForm.formData}
              onChange={handleForm}
            />
          </div>
        </Section>
      </div>

      {error && <span className='text-danger error-block'>{error}</span>}
    </Modal>
  );
};

const mapStateToProps = ({ authReducer, languageReducer, calendar }) => ({
  language: languageReducer.language,
  user: authReducer.user,
  iqsBookingDropdownData: calendar.iqsBookingDropdownData,
});

export default connect(mapStateToProps, { fetchIqsBookingDropdownData, createIqsRequest })(ComfirmBookingPopup);
