import FormComponent from "library/common/commonComponents/FormComponent/Optimized";
import React from "react";
import strings from "resources/locales/Translate";
import Section from 'library/common/commonComponents/Section/Section';
import {
  addZeissCompanayModel,
  contactCreatorModel,
  crmAndCustomerModel,
  customerContactModel,
} from './Information.contants';
import Button from "library/common/commonComponents/Button/Button";
import CompanyPopup from '../CompanyPopup/CompanyPopup.component';
import CustomerContactPopup from '../CustomerContanctPopup/CustomerContactPopup.component';
import { useInformation } from './Information.hook';
import { connect } from 'react-redux';
import { EditOutlineIcon } from 'library/common/commonComponents/Icon/SvgIcons/Icons';

export const Information = props => {
  const {
    onClickCompany,
    onClickCustomerContact,
    getContactCustomerModel,
    getAddZeissCompanayModel,
    getContactCreatorModel,
  } = useInformation(props);
  return (
    <div className='col-12 mt-4'>
      <Section sectionTitle={strings.crmCustomer}>
        <div className=''>
          <FormComponent
            model={crmAndCustomerModel}
            formName='crmAndCustomerForm'
            // formValue={kitStatusForm.formData}
            // onChange={handleForm}
          />
        </div>
        <div className='d-flex'>
          <Button
            value={strings.editCompany}
            onClick={() => {}}
            bgFill={false}
            styleClass='add-button adjust-add-location-manager-btn adjust-button-width'
            icon={<EditOutlineIcon width='14' height='14' />}
            newVersionUi={true}
          />
          <Button
            value={strings.createCompany}
            onClick={onClickCompany}
            bgFill={false}
            styleClass='add-button adjust-add-location-manager-btn adjust-button-width ml-4'
            iconName='plus'
            newVersionUi={true}
          />
        </div>
      </Section>
      <div className='mt-4'>
        <Section sectionTitle={strings.contacts}>
          <div className=''>
            <FormComponent
              model={getContactCustomerModel()}
              formName='crmAndCustomerForm'
              // formValue={kitStatusForm.formData}
              // onChange={handleForm}
            />
          </div>
          <div className='d-flex'>
            <Button
              value={strings.addCustomerContact}
              onClick={() => {}}
              bgFill={false}
              styleClass='add-button adjust-add-location-manager-btn adjust-button-width'
              icon={<EditOutlineIcon width='14' height='14' />}
              newVersionUi={true}
            />
            <Button
              value={strings.createCustomerContact}
              onClick={onClickCustomerContact}
              bgFill={false}
              styleClass='add-button adjust-add-location-manager-btn adjust-button-width ml-4'
              iconName='plus'
              newVersionUi={true}
            />
          </div>
          <div className='mt-4'>
            <FormComponent
              model={getContactCreatorModel()}
              formName='crmAndCustomerForm'
              // formValue={kitStatusForm.formData}
              // onChange={handleForm}
            />
          </div>
          <div className='mt-4 col-12 d-flex p-0'>
            <div className='col-6'>
              <FormComponent
                model={getAddZeissCompanayModel()}
                formName='crmAndCustomerForm'
                // formValue={kitStatusForm.formData}
                // onChange={handleForm}
              />
            </div>

            <Button
              value={strings.addZeissContact}
              onClick={() => {}}
              bgFill={false}
              styleClass='add-button adjust-add-location-manager-btn adjust-button-width ml-4'
              iconName='plus'
              newVersionUi={true}
            />
          </div>
        </Section>
      </div>
      <div className='d-flex'>
            <Button
              value={strings.save}
              onClick={() => {}}
              bgFill={true}
              styleClass='add-button adjust-add-location-manager-btn save-next-pev-button'
              newVersionUi={true}
            />
            <Button
              value={strings.cancel}
              onClick={() => {}}
              bgFill={false}
              styleClass='add-button adjust-add-location-manager-btn save-next-pev-button ml-4'
              newVersionUi={true}
            />
          </div>
      <CompanyPopup id='companyPopup' />
      <CustomerContactPopup id='customerContactPopup' />
    </div>
  );
};


const mapStateToProps = ({ authReducer, iqsRequestReducer, masterDataReducer }) => ({
  user: authReducer.user,
  allDropdownData: iqsRequestReducer.allDropdownData,
  usersList: masterDataReducer.usersList,
});

export default connect(mapStateToProps, {})(Information);