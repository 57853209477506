import React from 'react';
import cn from 'classnames';
import { ViewTypes } from 'library/common/commonComponents/ReactBigScheduler';
import Icon from 'library/common/commonComponents/Icon/Icon';
import moment from 'moment';
import { NavigationLeftArrow, NavigationRightArrow } from 'library/common/commonComponents/Icon/SvgIcons/Icons';
import { checkStockRoles } from 'library/utilities/checkRoles';

/**
 * @fileoverview A simple component the renders a bar on Calendar
 * @returns React Node
 */
function SlotItem({ event, mustAddCssClass, mustBeHeight, selectedViewType, calendarConfig, handleRemoveEvent, user }) {
  
  const textOnly = event.commentOnly;
  const currentEvent = calendarConfig?.resources.find(item => item.id === event.resourceId);
  let slotViewAccess = false
  try{
    if(currentEvent?.stockId && currentEvent?.stockId > 0){
      if(user.admin) slotViewAccess = true;
      else slotViewAccess = checkStockRoles(["Dispatcher", "Stock Controller"], user, {id: parseInt(currentEvent.stockId)})
    }
  }catch(e){
    slotViewAccess = false
  }
  
  const getStyles = (eventType, isMonthView) => {  
    const styles = {
      type1: isMonthView ? 'month-view-request-type1' : 'request-type1',
      type2: isMonthView ? 'month-view-request-type2' : 'request-type2',
      type3: isMonthView ? 'month-view-request-type3' : 'request-type3',
    };

    const borders = {
      type1: 'request-type1-border',
      type2: 'request-type2-border',
      type3: 'request-type3-border',
    };

    return {
      style: styles[eventType],
      border: isMonthView ? null : borders[eventType],
    };
  };

  const { style: style1, border: eventBorder } = getStyles(event.type, selectedViewType === ViewTypes.Custom2);

  if (event.isNewEvent) {
    const differenceOfDays = moment(event.end).diff(moment(event.start), 'd') + 1;
    
    return(
      // <div>
      //   <div className='new-event-slot'>
      //   <Icon name='plus' width={16} height={16} fill='#fff' />
      // </div>
      // </div>  
      <div
        key={event.id}
        className={`new-event-slot ${differenceOfDays < 3 ? 'justify-content-center' : ''}`}
        // style={divStyle}
      >
        {differenceOfDays >= 3 && <div className='selected-from-date new-slot-bg-color ml-1'><NavigationLeftArrow width={12} height={12}/></div>}
        <div className='new-slot-bg-color'>
          <Icon name='plus' width={16} height={16} fill='#fff' />
        </div>
        <div className='d-flex justify-content-center align-content-center'>
            <div className='' style={{backgroundColor:'red', paddingInline: 5, marginInline: 3}} onClick={(e) => {e.stopPropagation()
                handleRemoveEvent(calendarConfig, event)
              }}>
              {/* <button className='delete-button' > */}
                <Icon name='cross' width={12} height={12} fill='#fff' />
              {/* </button> */}
            </div>
          {differenceOfDays >= 3 && (
            <div className='selected-to-date new-slot-bg-color ml-1'>
              <NavigationRightArrow width={12} height={12}/>
            </div>
          )}
        </div>
      </div>
    );
  }

  if (ViewTypes.Custom2 === selectedViewType) {
    return (
      <div
        className={`calendar-slot  ${
          event.publicHoliday ? 'public-holiday-comment-background' : event.publicHoliday === false ? 'location-comment-background': ''
        }`}
        style={{ height: ViewTypes.Custom2 === selectedViewType && textOnly ? mustBeHeight : '' }}
      >
        {!textOnly ? <div className={`${style1}`}></div> : <p className='comment-slots'>{event.title}</p>}
      </div>
    );
  }

  return (
    <div
      key={event.id}
      className={cn(mustAddCssClass, 'calendar-slot', {
        'location-comment-background': event.publicHoliday === false,
        'public-holiday-comment-background': event.publicHoliday,
      })}
      style={{
        height: mustBeHeight,
      }}
    >
      {!textOnly ? (
        <>
          {' '}
          <div className={`d-flex align-items-center justify-content-between header ${style1}`}>
            <small className={`${event.type === 'type2' ? 'request-type2-time-color' : ''}`} style={{color: '#fff'}}>{`${moment(event?.start).format('HH:mm')}`}</small>
            <small className={`${event.type === 'type2' ? 'request-type2-time-color' : ''}`} style={{color: '#fff'}}>{`${moment(event?.end).format('HH:mm')}`}</small>
          </div>
          <div className={`slot-name-container ${eventBorder}`} style={{color: !slotViewAccess ? "transparent": ""}}>{event.title}</div>
        </>
      ) : (
        <p className={`comment-slots`}>{event.title}</p>
      )}
    </div>
  );
}

export default SlotItem;
