import React, { useRef, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import { useState } from 'react';

import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import InputField from '../InputField';
import './dateTimePicker.style.scss';
import cn from "classnames"

export const DateAndTimePicker = ({
  selectedDateTime,
  onChange,
  inputClassName,
  disabled,
  isClearable,
  isDisabled,
  timeFormat = 'HH:mm',
  dateFormat = 'dd.MM.yyyy HH:mm',
  field = '',
  label = '',
  error = '',
  placeholder = '',
  timeIntervals = 30,
  excludeTimesBefore = 6,
  excludeTimesAfter = 17,
  minDate
}) => {
  const datePickerRef = useRef();
  const [viewDate, setViewDate] = useState(new Date());

  /**
   * Why this DOM manipulation: When month changes, the height of the month and time picker
   * is not matching, hence on `viewDate` change we are setting the calendar height.
   * `+50` is margin height of that calendar styles have which not covered by `offsetHeight`
   */
  useEffect(() => {
    const calendar = document.querySelector('.react-datepicker__month');
    const timeList = document.querySelector('.react-datepicker__time-list');
    if (calendar && timeList) {
      const calendarHeight = calendar.offsetHeight;
      timeList.style.height = `${calendarHeight + 50}px`;
    }
  }, [viewDate]);

  const highlightOtherMonthDays = (date, currentViewDate) => {
    const displayedMonth = currentViewDate.getMonth();
    const isSameMonth = date.getMonth() === displayedMonth;
    return isSameMonth ? '' : 'react-datepicker__day--outside-month';
  };

  const handleOnChange = date => {
    if (date) {
      onChange(date, field);
    }
  };

  const handleOpenDateTimePicker = () => {
    if (datePickerRef.current) {
      datePickerRef.current.setOpen(true);
      if (datePickerRef.current.input) {
        datePickerRef.current.input.onChange = () => {};
        datePickerRef.current.input.readOnly = true;
      }
    }
  };

  /**
   * Method to return the excluded dates based on the `excludeTimesBefore` and `excludeTimesAfter`
   * for each interval. 
   * @returns Exclude dates and time intervals
   */
  const getExcludedTimes = () => {
    const times = [];
    for (let i = 0; i < 24; i++) {
      if (i < excludeTimesBefore || i > excludeTimesAfter) {
        let interval = 0;
        while(interval < 60){
          times.push(new Date(new Date().getFullYear(), 0, 1, i, interval));
          interval += timeIntervals;
        }
      }
    }
    return times;
  };

  return (
    <DatePicker
      ref={datePickerRef}
      selected={selectedDateTime}
      onChange={handleOnChange}
      showTimeSelect
      customInput={
        <InputField
          label={label}
          value={selectedDateTime ? moment(selectedDateTime).format('DD.MM.YYYY HH:mm') : 'Please select...'}
          inputClassName={cn('date-picker-input', inputClassName)}
          onfocus={handleOpenDateTimePicker}
          icon={'calendar'}
          iconClassName='datetime-picker-icon'
          appendIcon={true}
          className='cursor-pointer'
          placeholder={label}
          onChange={() => {}}
          error={error}
          onIconClick={handleOpenDateTimePicker}
        />
      }
      timeFormat={timeFormat}
      dateFormat={dateFormat}
      disabled={disabled}
      isClearable={isClearable}
      calendarClassName='app-datetime-picker-calendar'
      dayClassName={date => highlightOtherMonthDays(date, viewDate)}
      onMonthChange={date => setViewDate(date)}
      inputProps={{ readOnly: true }}
      placeholderText={placeholder}
      excludeTimes={getExcludedTimes()}
      timeIntervals={timeIntervals}
      openToDate={selectedDateTime || new Date()} // Use selectedDateTime as the fallback
      minDate={minDate}
    />
  );
};

export default DateAndTimePicker;
