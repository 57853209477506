import * as $ from 'jquery';
import { addZeissCompanayModel, contactCreatorModel, customerContactModel } from './Information.contants';

export const useInformation = ({ id, allDropdownData, usersList }) => {
  const onClickCompany = () => {
    $(`#companyPopup`).modal('show');
  };
  const onClickCustomerContact = () => {
    $(`#customerContactPopup`).modal('show');
  };

  const getContactCustomerModel = () => {
    const model = [...customerContactModel];
    model[1].options = allDropdownData?.IqRequestCustomerContactRole || [];
    model[4].options = allDropdownData?.IqRequestCustomerContactRole || [];
    return model;
  };

  const getAddZeissCompanayModel = () => {
    const model = [...addZeissCompanayModel];
    model[0].options = usersList;
    model[1].options = allDropdownData?.IqRequestContactsRole || [];
    return model;
  };

  const getContactCreatorModel = () => {
    const model = [...contactCreatorModel];
    model[0].options = usersList;
    model[1].options = usersList;
    model[2].options = usersList;
    model[3].options = usersList;

    return model;
  };

  return {
    onClickCompany,
    onClickCustomerContact,
    getContactCustomerModel,
    getAddZeissCompanayModel,
    getContactCreatorModel,
  };
};
