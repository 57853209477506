import { fetchMasterDataService, fetchMasterDataServicePost } from 'library/api/master-data.service';
import { URLS } from 'library/common/commonConstants/ApiUrlConstants';
import { FETCH_IQS_BOOKING_DROPDOWN_DATA, FETCH_IQS_REQUESTS_EVENTS, IQS_CALENDAR_RESOURCES, IQS_LOCATIONS_IDS } from '../calendarConstants';
import { changeLoader, toggleActionMessage } from 'library/common/commonActions/AppActionsActions';
import { RESOURCE_TYPES } from 'library/common/commonConstants/kitConstants';
import { capitalize } from 'lodash';
import moment from 'moment';
import { scrollToTop } from 'library/utilities/scrollActions';

const iqsKitsGraphql =
  'kitName,kitInformationId,serialNumber,stockInformation[id,locationName],availabilityFrom,availabilityTo,dateOfManufacture,systemMainComponent[systemMainComponentName],inventoryNumber,lastMaintenanceDate,warehouseRoom[roomName,id],' +
  'resourceType,resourceCategory';

export const fetchIqsCalendarResources = filters => async dispatch => {
  dispatch(changeLoader(true));
  let dataToSend = {
    stockIds: filters.stock || [],
    productHierarchyIds: filters.productHierarchy || [],
    systemClassIds: filters.systemClass || [],
    systemMainComponentIds: filters.systemMainComponent || [],
    page: 0,
    size: 20,
    fromDate: filters.dispalyStartDate,
    toDate: filters.dispalyEndDate
  };
  try {
    const response = await fetchMasterDataServicePost(URLS.getIqsCalendarResources, dataToSend);
    const resources = prepareResourcesForCalendar(response.data || []);
    const events = prepareEventsData(response?.data?.events || [], response?.data?.personsIds);
    dispatch({ type: IQS_CALENDAR_RESOURCES, payload: resources });
    dispatch({ type: IQS_LOCATIONS_IDS, payload: response.data || [] });
    dispatch({ type: FETCH_IQS_REQUESTS_EVENTS, payload: events || [] });
    return { success: true, data: response };
  } catch (err) {
    console.log(err);
    dispatch({ type: IQS_CALENDAR_RESOURCES, payload: [] });
  } finally {
    dispatch(changeLoader(false));
  }
};

export const getResourceType = resourceType => {
  switch (resourceType) {
    case RESOURCE_TYPES.EQUIPMENT:
      return 'Equipment';
    case RESOURCE_TYPES.SYSTEM:
      return 'System';
    default:
      return 'Other';
  }
};

/**
 * Prepares resources for the calendar view.
 * Groups data into systems, equipment, and other categories while maintaining order and avoiding duplication.
 *
 * @param {Object} data - Array of kit information objects to process.
 * @returns {Array} - A flattened, ordered array of resources grouped by stock, room, and category.
 */
export const prepareResourcesForCalendar = (data = {}) => {
  const systems = data?.systems;
  const equipment = data?.equipments;
  const other = data?.other;
  const comments = data?.comments;
  const persons = data?.person;
  const resources = [];
  const events = data.events;
  resources.push(...(prepareCommentResources(comments) || []));
  resources.push(...(prepareResourceGrouping('systems', systems) || []));
  resources.push(...(prepareResourceGrouping('equipment', equipment) || []));
  resources.push(...(prepareResourceGrouping('other', other) || []));
  resources.push(...(preparePersonResources(persons) || []));
  return resources;
};

const prepareCommentResources = (data = []) => {
  const resources = [];
  const root = {
    id: 'comments',
    name: 'Comments',
    groupOnly: true,
  };
  resources.push(root);
  if (data && data.length) {
    data.forEach((resource, index) => {
      const { locationName, locationShortName, stockId } = resource;
      resources.push({
        id: `${stockId}__comments`,
        name: locationName,
        parentId: 'comments',
        locationName,
        locationShortName,
        stockId,
      });
    });
  }
  return resources;
};

const prepareResourceGrouping = (resourceType, data = []) => {
  const resources = [];
  const root = {
    id: resourceType,
    name: capitalize(resourceType),
    groupOnly: true,
  };
  resources.push(root);
  data.forEach((resourceGroup, index) => {
    const { stockId, roomName, locationName, resources: mainResources, roomId } = resourceGroup;
    /**
     * Why is the parent ID structured as `collapsable__${stockId}_${roomId}_${resourceType}`?
     * The logic for toggling the chevron icon inside the calendar relies on grouping rows.
     * This structure ensures unique grouping and avoids unintended toggling behavior:
     *
     * 1. `collapsable__`: Prefix used to target grouped rows for adding background color (see CSS).
     * 2. `stockId`, `roomId`: Under a stock, there can be different types of kits (e.g., systems, equipment, others).
     *    Using only `stockId` could cause conflicts if multiple kits of different types share the same stock ID.
     *    Adding `roomId` helps differentiate between these.
     * 3. `resourceType`: Acts as a fallback to ensure uniqueness for grouping when `stockId` and `roomId` are insufficient.
     *
     */
    const resourceParent = {
      id: `collapsable__${stockId}_${roomId}_${resourceType}`,
      name: `${locationName} ${roomName}` || roomName,
      groupOnly: true,
      parentId: resourceType,
      roomName,
      locationName,
    };
    resources.push(resourceParent);
    if (mainResources && mainResources.length) {
      mainResources.forEach((res, index) => {
        resources.push({
          id: `${res.kitId}`,
          name: res.kitName,
          parentId: `collapsable__${stockId}_${roomId}_${resourceType}`,
          ...res,
          roomName,
          locationName,
          stockId,
        });
      });
    }
  });
  return resources;
};

const preparePersonResources = (data = []) => {
  const resources = [];
  const root = {
    id: 'person',
    name: 'Person',
    groupOnly: true,
  };
  resources.push(root);
  data.forEach((resourceGroup, index) => {
    const { stockId, locationName, operators } = resourceGroup;
    const resourceParent = {
      id: `collapsable__${stockId}_person`,
      name: locationName,
      groupOnly: true,
      parentId: 'person',
      locationName,
    };
    resources.push(resourceParent);
    if (operators && operators.length) {
      operators.forEach((res, index) => {
        resources.push({
          id: `${res.accountId}__${stockId}`,
          name: `${res.firstName} ${res.lastName}`,
          parentId: `collapsable__${stockId}_person`,
          type: 'PERSON',
          ...res,
        });
      });
    }
  });
  return resources;
};

const prepareEventsData = (data, persons) => {
  const newPersonEventArray = data.flatMap((event) =>
    event.personIds.length > 0
      ? event.personIds.flatMap((personId) =>
          (persons[personId] || []).map((resource) => ({
            ...event,
            start: new Date(event.bookingFrom),
            end: new Date(event.bookingTo),
            id: event.bookingId,
            resourceId: `${personId}__${resource}`,
            isNewEvent: false,
            commentOnly: false,
            type: "type1",
            title: event.otherBookingTitle,
          }))
        )
      : [] // Return an empty array if personIds is empty
  );
  const transformedEvents = data?.flatMap(event =>
    event.resourceIds.map(resourceId => ({
      ...event,
      start: new Date(event.bookingFrom),
      end: new Date(event.bookingTo),
      id: event.bookingId,
      resourceId: `${resourceId}`,
      isNewEvent: false,
      commentOnly: false,
      type: 'type1',
      title: event.otherBookingTitle,
      resizable: false,
      movable: false
    }))
  );

  return [...transformedEvents, ...newPersonEventArray];
}

export const fetchIqsBookingDropdownData = () => async dispatch => {
  try {
      const { data } = await fetchMasterDataService(URLS.iqsBookingDrodownData);
      dispatch({
        type: FETCH_IQS_BOOKING_DROPDOWN_DATA,
        payload: data,
      });
      return ({success: true})
    }catch (error) {
      dispatch({
        type: FETCH_IQS_BOOKING_DROPDOWN_DATA,
        payload: [],
      });
      return ({success: false})
    }
}

export const createIqsRequest = ( dataToSend ) => async (dispatch, getState) => {
  try {
    const response = await fetchMasterDataServicePost(URLS.iqsCreateRequest, dataToSend);
    const calendarState = getState().calendar;
    const events =  prepareEventsData([response?.data] || [], calendarState?.iqsCommentsLocationsIds?.personsIds);
    dispatch({ type: FETCH_IQS_REQUESTS_EVENTS, payload: [...calendarState.iqsRequestEvents, ...events] || [] });
    scrollToTop(500);
    dispatch(toggleActionMessage(true, 'success', 'iqsBookingSucessMessage'));
    return ({success: true})
  } catch (error) {
    return ({success: false, error})
  }
};
