import React, { Component } from 'react';
import { connect } from 'react-redux';
import { isEqual,has} from 'lodash';

import strings from 'resources/locales/Translate';
import { images } from 'library/common/commonConstants/ImageConstants';
import { toggleActionMessage } from 'library/common/commonActions/AppActionsActions';
import { scrollToTop } from 'library/utilities/scrollActions';
import Icon from 'library/common/commonComponents/Icon/Icon';

import './stylesheet.scss';

const filterData = ({ field, data, filter, displayValue, optionsArrayKey, hasSection }) => {
  let filteredData = [];
  let totalItems = 0;
  if (hasSection) {
    let single = {};
    data.forEach(menu => {
      single = { ...menu };
      if (menu[optionsArrayKey]) {
        single[optionsArrayKey] = menu[optionsArrayKey].filter(item => {
          let displayValueString = displayValue.map(display => {
            if (display.name) {
              return item[display.key] + display.separator + item[display.name];
            }
            return item[display.key] + display.separator;
          });
          const filteredGood = displayValueString.join('').toLowerCase().includes(filter.toLowerCase());
          if (filteredGood) {
            totalItems++;
          }
          return filteredGood;
        });
        filteredData.push(single);
        single = {};
      }
    });
  } else {
    filteredData = data.length > 0 && data.filter(item => {
      let displayValueString = displayValue.map(display => {
        if (display.name) {
          return item[display.key] + display.separator + item[display.name];
        }
        return item[display.key] + display.separator;
      });
      const filteredGood = displayValueString.join('').toLowerCase().includes(filter.toLowerCase());
      if (filteredGood) {
        totalItems++;
      }
      return filteredGood;
    });
  }
  return { data: filteredData, totalItems };
};

export class Dropdown extends Component {
  static getDerivedStateFromProps(props, state) {
    if (props.data && (!state.fullData || !isEqual(props.data, state.fullData))) {
      const { data, totalItems } = filterData({ ...props, filter: state.filter });
      return {
        data,
        fullData: props.data,
        totalItems,
      };
    }
    if (
      props.multiSelect &&
      props.value &&
      props.value.length &&
      (!props.fullValue || props.value.length > props.fullValue.length) &&
      props.data &&
      props.data.length &&
      !state.updated &&
      props.onChange
    ) {
      const fullValue = [];
      props.value.forEach(item => {
        const current = props.data.find(dataItem => dataItem[props.idKey] === item);
        if (current) {
          fullValue.push(current);
        }
      });
      props.onChange(props.value, props.field, fullValue);
      return { updated: true };
    }
    if (!props.multiSelect && props.value && !props.fullValue && props.data && props.data.length && !state.updated) {
      const fullValue = props.data.find(dataItem => dataItem[props.idKey] === props.value);
      props.onChange(props.value, props.field, fullValue);
      return { updated: true };
    }
    return null;
  }
  constructor(props) {
    super(props);
    this.dropdown = React.createRef();
    this.state = {
      fullData: null,
      data: null,
      totalItems: 0,
      filter: '',
      isDropdownOpen: false,
      error: false,
      isInfoOpen: false,
      updated: false,
    };
  }

  componentDidMount() {
    document.addEventListener('click', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutside);
  }

  handleClickOutside = e => {
    if (this.dropdown.contains(e.target)) {
      return;
    }
    this.setState({ isDropdownOpen: false });
  };

  toggleDropdown = () => {
    if (!this.props.disabled) {
      this.setState({ isDropdownOpen: !this.state.isDropdownOpen });
    }
  };

  toggleInfoMessage = () => {
    this.setState({ isInfoOpen: !this.state.isInfoOpen });
  };

  handleSelect = value => () => {
    const { multiSelect, field, idKey, onChange } = this.props;
    if (multiSelect) {
      let { value: selectedValue, fullValue: selectedFullValue } = this.props;
      selectedValue = selectedValue || [];
      selectedFullValue = selectedFullValue || [];
      if (selectedValue.includes(value[idKey])) {
        selectedValue = selectedValue.filter(item => item !== value[idKey]);
        selectedFullValue = selectedFullValue.filter(item => item[idKey] !== value[idKey]);
      } else {
        selectedValue.push(value[idKey]);
        if(Array.isArray(selectedFullValue)){
          selectedFullValue.push(value);
        }else{
          selectedFullValue = [value];
        }
      }
      onChange(selectedValue, field, selectedFullValue);
    } else {
      if (value[idKey] || value[idKey] === 0) {
        this.setState({ isDropdownOpen: false }, () => {
          onChange(value[idKey], field, value);
        });
      } else if (idKey === 'accountId') {
        this.props.toggleActionMessage(true, 'error', strings.emptyAccountId, true);
        scrollToTop(500);
      } else {
        this.props.toggleActionMessage(true, 'error', strings.emptyDropDownField(idKey), true);
        scrollToTop(500);
      }
    }
  };

  handleSelectAll = (data, selectedAll, multiSelect) => () => {
    const { hasSection, field, idKey, onChange, optionsArrayKey } = this.props;
    let value = [];
    let fullValue = [];
    if (!selectedAll) {
      if (hasSection) {
        data.forEach((menu, i) => {
          if (menu[optionsArrayKey] && menu[optionsArrayKey].length) {
            menu[optionsArrayKey].forEach(item => {
              value.push(item[idKey]);
              fullValue.push(item);
            });
          }
        });
      } else {
        value = data.map(item => item[idKey]);
        fullValue = data;
      }
    } else if (!hasSection || !multiSelect) {
      value = null;
      fullValue = null;
    }
    if (!multiSelect) {
      this.toggleDropdown();
    }
    onChange(value, field, fullValue);
  };

  handleFilter = e => {
    const filter = e.target.value;
    this.setState(prevState => {
      const { data, totalItems } = filterData({ ...this.props, filter });
      return { data, totalItems, filter };
    });
  };

  getPlaceholder = () => {
    const { displayValue, multiSelect, placeholder, fullValue } = this.props;
    let displayPlaceholder = placeholder;
    if (!multiSelect && fullValue) {
      displayPlaceholder = displayValue
        .map(display => fullValue[display.key] && fullValue[display.key] + display.separator)
        .join('');
    } else if (fullValue && fullValue.length) {
      displayPlaceholder = displayValue.map(display => fullValue[0][display.key] + display.separator).join('');
      if (fullValue.length > 1) {
        displayPlaceholder += ` + ${fullValue.length - 1}`;
      }
    }
    if ((fullValue && fullValue.length === this.state.totalItems) && fullValue.length > 0) {
      displayPlaceholder = displayValue.map(display => {
        if ((fullValue && has(fullValue[0], display.key)) && fullValue.length === 1) {
            return fullValue[0][display.key] + display.separator
        }else {
          return strings.selectAll;
        }
      })
    }
    return { placeholder: (displayPlaceholder || placeholder), icon: fullValue && fullValue.icon };
  };

  renderSelectAll = (data, optionsLength, multiSelect) => {
    const { selectAllOption, selectAllPlaceholder, value } = this.props;
    if (!selectAllOption) {
      return null;
    }
    const selectedAll = optionsLength === -1 || (value && optionsLength === value.length);
    return (
      <div
        key='select-all'
        onClick={this.handleSelectAll(data, selectedAll, multiSelect)}
        data-test='dropdown-option'
        className='dropdown-option d-flex align-items-center mb-2'
      >
        {multiSelect && (
          <img
            src={selectedAll ? images.checkboxSelected : images.checkboxDeselected}
            alt='checkbox'
            className='mr-2'
          />
        )}
        {strings[selectAllPlaceholder] || strings.selectAll}
      </div>
    );
  };

  renderNonSectionData() {
    const { data } = this.state;
    const { multiSelect, displayValue, idKey, value } = this.props;
    let options = [];
    if (data && data.length > 0) {
      data.forEach((item, key) => {
        options.push(
          <div
            onClick={!item.disabled ? this.handleSelect(item) : null}
            key={key}
            data-test='dropdown-option'
            className={`dropdown-option d-flex align-items-center mb-2 ${!!item.disabled && 'opacity-50'}`}
          >
            {multiSelect ? (
              <img
                src={value && value.includes(item[idKey]) ? images.checkboxSelected : images.checkboxDeselected}
                alt='checkbox'
                className='mr-3'
              />
            ) : null}

            {!!item.icon && (
              <div className={`mr-2 ${item.icon}`} />
            )}

            {displayValue.map(valueFetch => {
              if (valueFetch.name) {
                return item[valueFetch.key] + valueFetch.separator + item[valueFetch.name];
              }
              return item[valueFetch.key] + valueFetch.separator;
            })}
          </div>,
        );
      });
      if (multiSelect) {
        options.unshift(this.renderSelectAll(data, options.length, multiSelect));
      } else {
        options.unshift(this.renderSelectAll(data, -1, multiSelect));
      }
    } else {
      options.push(
        <div
          key={0}
          data-test='dropdown-option'
          className='dropdown-option d-flex justify-content-center align-items-center mb-2'
        >
          {strings.noDataFoundText}
        </div>,
      );
    }
    return options;
  }

  renderSectionableData() {
    const { multiSelect, idKey, displayValue, titleDisplay, optionsArrayKey, value } = this.props;
    const { data } = this.state;
    let options = [];
    let singleSection = [];
    let counter = 3;
    let itemCount = 0;
    if (data && data.length > 0) {
      data.forEach((menu, i) => {
        if (menu[optionsArrayKey] && menu[optionsArrayKey].length > 0) {
          itemCount += menu[optionsArrayKey].length;
          singleSection.push(
            <div className='single-section mb-3' key={i}>
              {titleDisplay.length > 0 && (
                <h6 className='mb-2 font-weight-bold'>
                  {titleDisplay.map(titleItem => {
                    return menu[titleItem.key] + titleItem.separator;
                  })}
                </h6>
              )}
              {menu[optionsArrayKey].map((item, key) => (
                <div
                  tabIndex={counter}
                  onClick={this.handleSelect(item)}
                  key={i + '-' + key}
                  data-test='dropdown-option'
                  className='dropdown-option d-flex align-items-center mb-2'
                >
                  {multiSelect ? (
                    <img
                      src={value && value.includes(item[idKey]) ? images.checkboxSelected : images.checkboxDeselected}
                      alt='checkbox'
                      className='mr-2'
                    />
                  ) : null}
                  {displayValue.map(valueFetch => {
                    return item[valueFetch.key] + valueFetch.separator;
                  })}
                </div>
              ))}
            </div>,
          );
          ++counter;
          options.push(singleSection);
          singleSection = [];
        }
      });
      if (multiSelect) {
        options.unshift(this.renderSelectAll(data, itemCount, multiSelect));
      } else {
        options.unshift(this.renderSelectAll(data, -1, multiSelect));
      }
    } else {
      options.push(
        <div
          key={0}
          data-test='dropdown-option'
          className='dropdown-option d-flex justify-content-center align-items-center mb-2'
        >
          {strings.noDataFoundText}
        </div>,
      );
    }
    return options;
  }

  handleOnClear = (e) => {
    e.stopPropagation();
    const { multiSelect, field, idKey, onChange } = this.props;
    if(multiSelect){
      onChange([], field, []);
    }else{
      onChange(null, field, null);
    }
  }

  render() {
    const {
      dataTest,
      hasSection,
      filter,
      label,
      isRequired,
      mainContainerStyle,
      disabled,
      showExtraInfo,
      infoMessage,
      filterDataPlaceholder,
      error,
      showRedAsterik,
      hideOptionalText,
      isInfoHasAttributes,
      newDropdownStyle,
      showClearIcon,
      value,
      multiSelect,
    } = this.props;
    const { isDropdownOpen } = this.state;
    const { placeholder, icon } = this.getPlaceholder();
    let enableClearIcon = showClearIcon &&  (multiSelect ? value?.length > 0: (value ? true: false));
    const placeHolder = placeholder && Array.isArray(placeholder) ? placeholder[0]: placeholder
    return (
      <div className={mainContainerStyle} data-test={dataTest}>
        <div className='label-container d-flex justify-content-between align-items-center'>
          <div className='d-flex position-relative'>
            <p className='dropdown-label'>{label} {isRequired && label && <span className='mandatory-asterik'>*</span>}</p>
            {showExtraInfo && <p className='input-info cursor-pointer'>i</p>}
            {showExtraInfo && isInfoHasAttributes
            ? <div className='info-message position-absolute'>{infoMessage}</div>
            : showExtraInfo && <div className='info-message position-absolute'>{strings[infoMessage]}</div>}
          </div>
        </div>

        <div
          className={`app-dropdown ${newDropdownStyle ? 'new-updated-dropdown': ''} cursor-pointer ${disabled && 'disabled'} ${!!error && 'input-error'}`}
          tabIndex={1}
          ref={el => (this.dropdown = el)}
        >
          <div
            className='dropdown-title d-flex justify-content-between align-items-center'
            data-test='dropdown-title'
            onClick={this.toggleDropdown}
          >
            {!!icon && (<div className={`${icon} mr-1`} />)}
            <span className={`dropdown-placeholder ${newDropdownStyle ? 'new-dropdown-placeholder' : ''} ${newDropdownStyle && placeHolder?.includes(strings.pleaseSelect) ? 'new-dropdown-placeholder' : 'slected-value-placeholder'}`}>{placeHolder}</span>
            {enableClearIcon &&<span className='dropdown-clear-icon mr-1' onClick={this.handleOnClear}>
              <Icon name='clear' width='10' height='10' viewBox='0 0 48 48'  fill={'#000'} />
            </span>}
            <Icon
              name='arrow'
              width={24}
              height={24}
              fill={newDropdownStyle ? '#000000': '#9a9b9c'}
              style={{ transform: isDropdownOpen ? 'rotate(-90deg)' : 'rotate(90deg)' }}
            />
          </div>

          {isDropdownOpen && (
            <div className='dropdown-items-container dropdown-shadow'>
              <div
                className='dropdown-title d-flex mb-2 justify-content-between align-items-center'
                onClick={this.toggleDropdown}
              >
                {!!icon && (<div className={`${icon} mr-1`} />)}
                <span className={`dropdown-placeholder ${newDropdownStyle ? 'new-dropdown-placeholder' : ''} ${newDropdownStyle && placeHolder?.includes(strings.pleaseSelect) ? 'new-dropdown-placeholder' : 'slected-value-placeholder'}`}>{placeHolder}</span>
                <Icon
                  name='arrow'
                  width={24}
                  height={24}
                  fill={newDropdownStyle ? '#000000': '#9a9b9c'}
                  style={{ transform: isDropdownOpen ? 'rotate(-90deg)' : 'rotate(90deg)' }}
                />
              </div>

              {filter && (
                <div>
                  <input
                    type='text'
                    className='form-control rounded-0 position-relative'
                    tabIndex={2}
                    placeholder={filterDataPlaceholder}
                    onChange={this.handleFilter}
                    value={this.state.filter}
                  />
                  <Icon name={'search'} width={24} height={24} fill={'#9a9b9c'} className='search-icon' />
                </div>
              )}

              <div className='options-container' key={0}>
                {hasSection ? this.renderSectionableData() : this.renderNonSectionData()}
              </div>
            </div>
          )}
        </div>
        {error && <span className='text-danger error-block'>{strings[error]}</span>}
      </div>
    );
  }
}

Dropdown.defaultProps = {
  hasSection: false,
  multiSelect: false,
  filter: false,
  isRequired: false,
  fetchValueFrom: [],
  optionsArrayKey: 'options',
  placeholder: '',
  label: '',
  data: [],
  excludeData: [],
  validators: [],
  displayValue: [],
  idKey: '',
  showExtraInfo: false,
  infoMessage: '',
  value: null,
  onError: () => {},
  onChangeValue: () => {},
  disabled: false,
  doValidateForm: false,
  titleDisplay: [],
  showRedAsterik: false,
  hideOptionalText: false,
  isInfoHasAttributes: false,
  newDropdownStyle: false,
  showClearIcon: false,
};

const mapStateToProps = ({ languageReducer }) => ({
  language: languageReducer,
});

export default connect(mapStateToProps, { toggleActionMessage }, null, { forwardRef: true })(Dropdown);
