import { useState, useEffect } from 'react';
import {
  confirmBookingInformationModel,
  confirmBookingOtherModel,
  confirmBookingRequestModel,
} from './ComfirmBookingPopup.constants';
import * as $ from 'jquery';
import { validateForm } from 'library/utilities/ValidateForm.util';
import moment from 'moment';
import strings from 'resources/locales/Translate';

export const useConfirmBookingPopup = ({
  id,
  fetchIqsBookingDropdownData,
  iqsBookingDropdownData,
  calendarConfig,
  createIqsRequest,
  selectedSystem,
  eventSelectedPopupOpen,
  setEventSelectedPopupOpen,
  setSelectedSystem,
  language,
  singleEventClickedId,
}) => {
  const [confirmBookingForm, setConfirmBookingForm] = useState({ formData: {} });
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  let currentSlectedSystem = [];
  if (selectedSystem.length > 0) {
    currentSlectedSystem = calendarConfig?.events.filter(
      event => event.isNewEvent === true,
    );
  } else {
    currentSlectedSystem = calendarConfig?.events.filter(event => event.isNewEvent === true && event.id === singleEventClickedId);
  }

  useEffect(() => {
    fetchIqsBookingDropdownData();
  }, [language]);

  useEffect(() => {
    if (currentSlectedSystem?.length > 0 && eventSelectedPopupOpen) {
      const startDate = new Date(currentSlectedSystem[0]?.start);
      const endDate = new Date(currentSlectedSystem[0]?.end);
      setConfirmBookingForm({
        formData: {
          bookingFrom: startDate,
          bookingTo: endDate,
        },
      });
    }
  }, [eventSelectedPopupOpen]);

  const handleForm = ({ values, field }) => {
    setError('');
    setConfirmBookingForm({
      ...confirmBookingForm,
      formData: {
        ...confirmBookingForm.formData,
        ...values,
      },
    });
  };

  const informationModel = () => {
    const model = [...confirmBookingInformationModel];
    model[0].options = iqsBookingDropdownData?.iqsRequestBookingTypes || [];
    model[1].options = iqsBookingDropdownData?.iqsRequestCategories || [];
    return model;
  };

  const otherModel = () => {
    const model = [...confirmBookingOtherModel];
    return model;
  };

  const requestModel = () => {
    const model = [...confirmBookingRequestModel];
    return model;
  };

  const onSaveClick = async () => {
    const validForm = validateForm({
      form: confirmBookingForm,
      model: informationModel(),
    });

    const validOtherForm = validateForm({
      form: confirmBookingForm,
      model: otherModel(),
    });

    if (!validForm.isFormValid || !validOtherForm.isFormValid) {
      return setConfirmBookingForm({ formData: { ...validForm.formData, ...validOtherForm.formData } });
    }
    const { bookingFrom, bookingTo, bookingType, bookingCategory, otherBookingTitle, otherBookingDescription } =
      confirmBookingForm.formData;
    if (moment(bookingTo).isSameOrBefore(moment(bookingFrom))) {
      return setError(strings.dateErrorMessage);
    }

    const dataToSend = {
      bookingFrom: moment(bookingFrom).utc().format('YYYY-MM-DDTHH:mm:ss[Z]'),
      bookingTo: moment(bookingTo).utc().format('YYYY-MM-DDTHH:mm:ss[Z]'),
      bookingType: bookingType,
      bookingCategory: bookingCategory,
      otherBookingTitle: otherBookingTitle,
      otherBookingDescription: otherBookingDescription,
      resources: currentSlectedSystem.filter(item => !item.resourceId.includes('__')).map(item => item.resourceId) || null,
      persons: currentSlectedSystem.filter(value => value.resourceId.includes('__')).map(value => value.resourceId.split('__')[0]) || null,
    };
    setLoading(true);
   const res = await createIqsRequest(dataToSend);
   setLoading(false);
   if (!res.success) {
    if (res?.error?.response?.status === 422 || res?.error?.response?.status === 403 || res?.error?.response?.status === 404) {
      setError(res.error.response.data.message);
    }else{
      setError(strings.somethingWentWrongMessage);
    }
  }else{
    setSelectedSystem([]);
    currentSlectedSystem = [];
    onCloseClick() 
  }
};

  const onCloseClick = () => {
    $(`#${id}`).modal('hide');
    setConfirmBookingForm({ formData: {} });
    setError('');
    setEventSelectedPopupOpen(false);
  };
  return {
    confirmBookingForm,
    informationModel,
    otherModel,
    requestModel,
    handleForm,
    onCloseClick,
    onSaveClick,
    error,
    currentSlectedSystem,
    loading,
  };
};
