export const schedularConfig = {
  eventItemHeight: 50,
  creatable: true,
  nonWorkingTimeBodyBgColor: '#DCE3E9',
  eventItemLineHeight: 55,
  dayStartFrom: 6,
  dayStopTo: 18,
  minuteStep: 30,
  nonWorkingTimeHeadBgColor: '#DCE3E9',
  customCellWidth: 30,
  headerEnabled: false,
  tableHeaderHeight: 60,
  besidesWidth: 70,
  groupOnlySlotColor: '#6AB0E2',
  customResourceTableWidth: 300,
  resourceName: '',
  scrollToSpecialMomentEnabled: false,
  checkConflict: true,
};

export const dayViewConfig = {
  dayStartFrom: 0,
  dayStopTo: 23,
  minuteStep: 60,
  customCellWidth: 60,
};

export const MonthViewConfig = {
  customCellWidth: 60,
  customResourceTableWidth: 300,
};
