import React from 'react';
import strings from 'resources/locales/Translate';
import './IqsNewRequest.styles.scss';
import FormComponent from 'library/common/commonComponents/FormComponent/Optimized';
import { headerModel, IqsRequestBreadcrumbData } from './IqsNewRequest.constants';
import { useIqsNewRequest } from './IqsNewRequest.hook';
import Tabs from 'library/common/commonComponents/Tabs/Tabs';
import Breadcrumbs from 'library/common/commonComponents/Breadcrumbs/Breadcrumbs';

export const IqsNewRequest = props => {
  const { tabs, getHeaderModel } = useIqsNewRequest(props);
  return (
    <div className='container-fluid'>
      <Breadcrumbs data={IqsRequestBreadcrumbData} />
      <div className='request-title p-2'>{strings.createRequest}</div>
      <div className='col-12 mt-4'>
        <FormComponent
          model={getHeaderModel()}
          formName='generalInformationForm'
          //   formValue={generalInformationForm.formData}
          //   onChange={handleForm}
        />
      </div>
      <div className='mt-4'>
        <Tabs tabs={tabs} newUiVersion={true} />
      </div>
    </div>
  );
};
export default IqsNewRequest;
