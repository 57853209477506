import { Redirect, Route, withRouter, Switch, useHistory } from 'react-router-dom';
import React, { Component } from 'react';
import { createBrowserHistory } from 'history';
import PrivateRoute from 'library/common/commonComponents/AppRoutes/PrivateRoute';
import { identifiers } from 'library/common/commonConstants/IdentifiersConstants';
import { fetchFromStorage, saveToStorage, removeFromStorage } from 'library/utilities/storage';
import { checkHasAccessToDlaLocationsWithRoles, checkHasMedUsWarehouseAccess, checkUserRoles } from 'library/utilities/checkRoles';
import { permissions } from 'library/common/commonConstants/permissionsConstants';
import moment from 'moment';

import CreateStock from 'modules/Stock/CreateStock/CreateStock.container';
import Homepage from 'modules/Homepage/HomepageContainer';
import CreateKit from 'modules/Kits/CreateKit';
import Documents from 'modules/Documents';
import StocksList from 'modules/Stock/StocksList/StocksListContainer';
import KitsList from 'modules/Kits/KitsList';
import KitDetails from 'modules/Kits/KitDetails/KitDetailsContainer';
import Calendar from 'modules/Calendar/CalendarContainer';
import LoanList from 'modules/LoanList/LoanListContainer';
import KitLoan from 'modules/KitLoan/KitLoanContainer';
import DeliveryAddresses from 'modules/DeliveryAddresses/DeliveryAddressesContainer';
import Booking from 'modules/Booking/BookingContainer';
import TransactionDetails from 'modules/Transactions/TransactionDetails';
import MasterData from 'modules/Administration/MasterData';
import Permissions from 'modules/DUSD/Permissions';
import Subscriptions from 'modules/DUSD/Subscriptions';
import CreateC2CLoan from 'modules/Transactions/CreateC2CLoan';
import C2CLoans from 'modules/Transactions/C2CLoans';
import Shop from 'modules/DemoSale/Shop';
import KitForSaleDetails from 'modules/DemoSale/KitForSaleDetails';
import CreateKitForSale from 'modules/DemoSale/CreateKitForSale';
import DusdTask from 'modules/DUSD/DusdTask';
import ExportData from 'modules/Administration/ExportData';
import StockDashboard from 'modules/Administration/StockDashboard';
import SystemClassesSubscriptions from 'modules/NotificationSettings';
import StatusList from 'modules/Administration/StatusList';
import AdministrationStatistics from 'modules/Administration/Statistics';
import MaintainStatus from 'modules/DUSD/MaintainStatus';
import InterfaceMonitoring from 'modules/Administration/InterfaceMonitoring';
import UserPermissions from 'modules/Administration/UserPermissions';
import ManageKitPictures from 'modules/Administration/ManageKitPictures';
import LLPermissions from 'modules/Administration/LLPermissions';
import CreateLLKit from 'modules/Kits/CreateLLKit';
import LLKitLoan from 'modules/LLKiLoan';
import LLManagement from 'modules/Administration/LLManagement';
import ActiveSystems from 'modules/DUSD/ActiveSystems';
import SapErpMapping from 'modules/Administration/SapErpMappingTable/SapErpMapping.container';
import RefurbishmentFees from 'modules/Administration/RefurbishmentFees/RefurbishmenFess.container';
import LabsLocationMessageWarning from 'modules/Calendar/LabsLocationWarningMessage.component';
import NoStockAccessPopup from 'modules/Homepage/NoStockAcessWelcomePopup/NoStockAcessWelcomePopup.component';
import MasterDataTabs from 'modules/Administration/MasterData/MasterDataTabs.component'
import CreateZmccKit from 'modules/Kits/CreateZmccKit';
import InfoMessagePopupForBorrower from 'modules/Homepage/InfoMessagePopupForBorrower/InfoMessagePopupForBorrower.component';
import SemBooking from 'modules/ZmccRequest/SEMBooking';
import ZmccTransactionDeatils from 'modules/ZmccTransactions/ZmccTransactionDetails';
import ZmccCrmRequestPopup from 'modules/Homepage/ZmccCrmRequestPopup/ZmccCrmRequestPopup.component';
import MainMySchedule from 'library/common/commonComponents/Header/Components/FullScreenMenu/Components/MySchedule/MainMySchedule.component';
import MyQueries from 'modules/Booking/Components/MyQueries';
import { BUSINESS_UNIT_NAMES } from 'library/utilities/constants';
import IqsNewRequest from 'modules/IqsNewRequest';

class Routes extends Component {
  state = {
    isLoggedIn: false,
  };

  componentWillMount() {
    let currentWindowUrl = window.location.href;
    currentWindowUrl = new URL(currentWindowUrl)
    let currentHost = currentWindowUrl.host
    let actualHost = process.env.REACT_APP_BASE_URL
    actualHost = new URL(actualHost)
    actualHost = actualHost.host
    const { location } = this.props;
    const { pathname, search } = location;
    if(actualHost !== currentHost && process.env.NODE_ENV !== 'development'){
      console.log(pathname, search, "<-------- pathname, search from DLA");
      window.location.href = `${process.env.REACT_APP_BASE_URL}/#${pathname + search}`
    }
    this.sendLoginInformationToServer();
  }

  paramsToObject(entries) {
    const result = {};
    for (const [key, value] of entries) {
      result[key] = value;
    }
    return result;
  }

  sendLoginInformationToServer() {
    const { setAuthentication, location, history, setLanguage, setOAuthentication } = this.props;
    let isLoggedIn = false;
    const { pathname, hash, search } = location;
    const path = hash.split('#')[1];

    const token = fetchFromStorage(identifiers.access_token);
    const user = fetchFromStorage(identifiers.user);

    if (pathname !== '/home' && pathname !== '/') {
      let navigatePath = fetchFromStorage(identifiers.hitUrl);
      if (
        (!navigatePath || navigatePath === 'null' || navigatePath == null || navigatePath === undefined) &&
        !isLoggedIn
      ) {
        let totalPath = pathname + location.search;
        saveToStorage(identifiers.hitUrl, totalPath);
      }
    }

    let crmParams;
    if (pathname.toLowerCase() === '/calendar' && search) {
      crmParams = this.paramsToObject(new URLSearchParams(location.search).entries());
      saveToStorage(identifiers.crmParams, crmParams);
    }

    let labslocationCrmParams;

    if (pathname.toLowerCase() === '/labs-location-calendar' && search) {
      labslocationCrmParams = this.paramsToObject(new URLSearchParams(location.search).entries());
      saveToStorage(identifiers.labslocationCrmParams, labslocationCrmParams);
    }

    let zmccCrmParams;
    if (pathname.toLowerCase() === '/zmcc' && search) {
      zmccCrmParams = this.paramsToObject(new URLSearchParams(location.search).entries());
      saveToStorage(identifiers.zmccCrmParams, zmccCrmParams);
      saveToStorage(identifiers.showCrmPopup, true);
    }

    const tempath = window.location.href.split('=')[1];
    if (window.location.href.includes('login_id')) {
      let loginId = tempath.substring(0, tempath.length - 2);
      setOAuthentication(undefined, undefined, loginId);
      // getToken(tempath.substring(0,tempath.length-2));
      isLoggedIn = true;
    } else if (token && !user) {
      setOAuthentication(token, undefined, undefined);
      isLoggedIn = true;
    } else if (user) {
      setOAuthentication(undefined, user, undefined);
      setLanguage(fetchFromStorage(identifiers.language));
      isLoggedIn = true;
    } else {
      setOAuthentication(undefined, undefined, undefined);
    }
    const temphistory = createBrowserHistory();
    const savedUrl = fetchFromStorage(identifiers.hitUrl);
    const showZmccCrmPopup = fetchFromStorage(identifiers.showCrmPopup);
     
    if (savedUrl && isLoggedIn) {
      if (showZmccCrmPopup) {

        temphistory.push('/');
      } else{
        temphistory.push(`#${savedUrl}`);
      }
      removeFromStorage(identifiers.hitUrl);
    } else {
      if (showZmccCrmPopup) {
        
        temphistory.push('/');
      }else{
        const currentPath = path ? `${path}${search}` : search;
        temphistory.push(currentPath || path);
       }
      
    }
  }

  render() {
    const { isLoggedIn, isAuthLoading, user,refurbishmentFessStockList } = this.props.authentication;
    const { pathname, search } = this.props.location;
    const isUserPartner = user && user.labLocationPartner;
    const isAdmin = user && user.admin;
    const loansSubMenu = fetchFromStorage(identifiers.zmccSubLoanMenus);
    const isLabsLocationStockController =
      user &&
      user.stockRoles.find(
        item => item.businessUnitName === 'RMS labs@location Partner' && item.roleName === 'Stock Controller' || item.roleName === 'Dispatcher' ,
      );
    const isZMCCStockController =
      user &&
      user.stockRoles.find(
        item => item.businessUnitName === 'RMS (ZMCC)' && item.roleName === 'Stock Controller',
      );
    const hasOnlyDustUserRole = user && user.dustUser;
    const hasStockDashboardPermissions = checkUserRoles(permissions.stockDashboardMenu, user);
    const hasStatisticsPermissions = checkUserRoles(permissions.statisticsMenu, user);
    const hasSubscriptionsPermissions = checkUserRoles(permissions.subscriptionsMenu, user);
    const hasDustTaskPermissions = checkUserRoles(permissions.dustTaskMenu, user);
    const hasDustPermissions = checkUserRoles(permissions.permissionsMenu, user);
    const hasMaintainStatusPermissions = checkUserRoles(permissions.maintainStatusMenu, user);
    const hasActiveSystemsPermissions = checkUserRoles(permissions.activeSystemsMenu, user);
    const hasUserPermissions = checkUserRoles(permissions.userPermissionsMenu, user);
    const rmsUser =
      user &&
      user.stockRoles &&
      user.stockRoles.filter(role => role.businessUnitName === 'RMS' && role.roleName !== 'Shop Manager').length;
    const llRMSUser =
      user &&
      user.stockRoles &&
      user.stockRoles.filter(
        role => role.businessUnitName === 'RMS labs@location Partner' && role.roleName !== 'Shop Manager',
      ).length;
    const allUserRoles = user && user.stockRoles && user.stockRoles.filter(role => role.businessUnitName).length;
    const noUserRoles = !isAdmin && !isUserPartner && allUserRoles === 0;
    const userHasOnlyLLRMS = llRMSUser === allUserRoles && !isAdmin;
    const hasLabLocationPermissions = isAdmin || isUserPartner || !!rmsUser || !!llRMSUser;
    const isLLManager =
      user &&
      user.stockRoles.find(
        item => item.businessUnitName === 'RMS labs@location Partner' && (item.roleName === 'Stock Controller' || item.roleName === 'Dispatcher') ,
      );
    const canViewSapErpMapping =
      user && (user.admin || user.stockRoles.some(item => item.roleName === 'Stock Controller'));
    const transactionPaths = [
      '/my-bookings',
      '/open-processes',
      '/loan-requests',
      '/incoming-goods',
      '/demo-kit-approval',
      '/outgoing-goods',
      '/cancelled-processes',
      '/archive-processes',
      '/sap-order-creation',
      '/good-receipt',
      '/list-receiving',
      '/list-reconditioning',
      '/return-goods-booking',
      '/cancellation-approval',
      '/transactions',
      '/team-bookings',
      '/my-queries',
      '/c2c',
    ];
    const partnerTransactionPaths = [
      '/my-bookings',
      '/open-processes',
      '/loan-requests',
      '/cancellation-approval',
      '/cancelled-processes',
      '/archive-processes',
      '/transactions',
    ];

    const isZmccTransactionsCheckboxActive = fetchFromStorage(identifiers.isZmccTransactionsCheckboxActive);
    const zmccTransactionPaths = [
      '/my-bookings',
      '/open-processes',
      '/loan-requests',
      '/cancellation-approval',
      '/cancelled-processes',
      '/archive-processes',
      '/transactions',
    ];

    const isLLEnabled = process.env.REACT_APP_IS_LABSLOCATION_ENABLED === 'true';
    const isShowBookingTeams = checkUserRoles(permissions.bookingTeams, user);
    function chnageTestValue () {
      saveToStorage(identifiers.stockPopup, false);
    }

    function hideBorrowerPopup () {
      saveToStorage(identifiers.showBorrowerInfoMessage, false);
    }
    function paramsToObject(entries) {
      const result = {};
      for (const [key, value] of entries) {
        result[key] = value;
      }
      return result;
    }
    function afterloggedInShowCrmPopup () {
     
      if (isLoggedIn && pathname.toLowerCase() === '/zmcc' && search) {
        let zmccCrmParams;
        saveToStorage(identifiers.showCrmPopup, true);
        zmccCrmParams = paramsToObject(new URLSearchParams(search).entries());
      saveToStorage(identifiers.zmccCrmParams, zmccCrmParams);

      }
    }

    const isShowStockPopup =fetchFromStorage(identifiers.stockPopup);
    const hideBorrowerPopupValue = fetchFromStorage(identifiers.showBorrowerInfoMessage);
    const hasNoAccessToStock = user && user.stockRoles && user.stockRoles.length === 0 && user.globalRoles && user.globalRoles.length === 0;
    const isborrowerStock = JSON.parse(process.env.REACT_APP_BORROWER_WAREHOUSEIDS);
    const checkForBorrowerInfoRoles = ['Borrower', 'Borrower ODX','Borrower MCS','Borrower SUR',];
    const findBrower = user && user.stockRoles && user.stockRoles.length > 0 && user.stockRoles.map(each => isborrowerStock.includes(each.stockId) && checkForBorrowerInfoRoles.includes(each.roleName));
    const showBorrowerInfoStartDate = '2023-04-01';
    const showBorrowerInfoEndDate = '2023-05-07';
    const currentDate =  moment().format('YYYY-MM-DD');
    const isShowBrrowerPoup = user && !user.admin && findBrower && findBrower.find(item => item === true) && currentDate > showBorrowerInfoStartDate && currentDate <= showBorrowerInfoEndDate;
    const isShowZmccPopup = fetchFromStorage(identifiers.showCrmPopup);
    const isShowPopupForAlreadyLoggedIn = !isShowZmccPopup && afterloggedInShowCrmPopup()
    const renderZmccDetailsComponent = this.props.location.pathname.includes('isZmcc');
    const hasMedUsWarehouseAccess = checkHasMedUsWarehouseAccess(user);
    const hasAccessToDlaLocations = checkHasAccessToDlaLocationsWithRoles(user, ['STOCK_CONTROLLER']);
    const hasAccessToIqsSscDeLocations = user?.stockRoles.some(stock => stock.businessUnitName === BUSINESS_UNIT_NAMES.IQS_SSC_DE) || isAdmin;

    if (isAuthLoading) {
      return <div className='global-loader' />;
    }

    if (isLoggedIn && hasOnlyDustUserRole) {
      return (
        <Switch>
          <Route path='/home' component={
            isShowZmccPopup ? () =>(<ZmccCrmRequestPopup  history ={this.props.history} isShowZmccPopup={isShowZmccPopup} />):
            hasNoAccessToStock && isShowStockPopup === null ?() => (<NoStockAccessPopup chnageTestValue={chnageTestValue} history ={this.props.history} user={user} />) 
           : isShowBrrowerPoup && hideBorrowerPopupValue === null ?() => (<InfoMessagePopupForBorrower hideBorrowerPopup={hideBorrowerPopup} history={this.props.history}/>)
          : Homepage} />
          <PrivateRoute exact={true} isLoggedIn={isLoggedIn} path='/stocks' component={StocksList} />
          <PrivateRoute exact={true} isLoggedIn={isLoggedIn} path='/dusd/maintain-status' component={MaintainStatus} />
          <PrivateRoute exact={true} isLoggedIn={isLoggedIn} path='/kit_details/:id' component={KitDetails} />
          <PrivateRoute exact={true} isLoggedIn={isLoggedIn} path='/documents' component={Documents} />
          <PrivateRoute exact={true} isLoggedIn={isLoggedIn} path='/delivery-addresses' component={DeliveryAddresses} />
          <PrivateRoute exact={true} isLoggedIn={isLoggedIn} path='/dusd/dusd-task' component={DusdTask} />
          <PrivateRoute exact={true} isLoggedIn={isLoggedIn} path='/dust/active-systems' component={ActiveSystems} />
          <PrivateRoute
            exact={true}
            isLoggedIn={isLoggedIn}
            path='/administration/status-list/:id'
            component={StatusList}
          />
          <PrivateRoute
            exact={true}
            isLoggedIn={isLoggedIn}
            path='/subscriptions'
            component={SystemClassesSubscriptions}
          />
          <PrivateRoute exact={true} isLoggedIn={isLoggedIn} path='/dusd/subscriptions' component={Subscriptions} />
          <Redirect to='/home' />
        </Switch>
      );
    }

    return (
      <div>
        {isLoggedIn ? (
          <Switch>
            <Route path='/home' component={
              isShowZmccPopup ? () =>(<ZmccCrmRequestPopup  history ={this.props.history} isShowZmccPopup={isShowZmccPopup}  />):
              hasNoAccessToStock && isShowStockPopup === null?() => (<NoStockAccessPopup chnageTestValue={chnageTestValue} history ={this.props.history} user={user} />)
            : isShowBrrowerPoup && hideBorrowerPopupValue === null ?() => (<InfoMessagePopupForBorrower hideBorrowerPopup={hideBorrowerPopup} history={this.props.history}/>)
            : Homepage} />
            <PrivateRoute exact={true} isLoggedIn={isLoggedIn} path='/stocks' component={StocksList} />
            <PrivateRoute exact={true} isLoggedIn={isLoggedIn} path='/create_stocks' component={CreateStock} />
            <PrivateRoute exact={true} isLoggedIn={isLoggedIn} path='/kits' component={KitsList} />
            {!isUserPartner && <PrivateRoute isLoggedIn={isLoggedIn} path='/demo-sale' component={Shop} />}
            <PrivateRoute
              key='create-kit-manually'
              exact={true}
              isLoggedIn={isLoggedIn}
              path='/create-kit-manually'
              component={CreateKitForSale}
            />
            <PrivateRoute
              key='create-single-item'
              exact={true}
              isLoggedIn={isLoggedIn}
              path='/create-single-item'
              component={CreateKitForSale}
            />
            <PrivateRoute
              key='create-kit'
              exact={true}
              isLoggedIn={isLoggedIn}
              path='/create-kit'
              component={CreateKit}
            />
            <PrivateRoute
              key='copy-kit'
              exact={true}
              isLoggedIn={isLoggedIn}
              path='/copy-kit/:id'
              component={CreateKit}
            />
            <PrivateRoute
              key='edit-kit'
              exact={true}
              isLoggedIn={isLoggedIn}
              path='/edit-kit/:id'
              component={CreateKit}
            />
            <PrivateRoute
              key='edit-manually-kit'
              exact={true}
              isLoggedIn={isLoggedIn}
              path='/edit-manually-kit/:id'
              component={CreateKitForSale}
            />
            <PrivateRoute
              key='edit-single-item'
              exact={true}
              isLoggedIn={isLoggedIn}
              path='/edit-single-item/:id'
              component={CreateKitForSale}
            />
            <PrivateRoute
              key='copy-manually-kit'
              exact={true}
              isLoggedIn={isLoggedIn}
              path='/copy-manually-kit/:id'
              component={CreateKitForSale}
            />
            <PrivateRoute
              key='copy-single-item'
              exact={true}
              isLoggedIn={isLoggedIn}
              path='/copy-single-item/:id'
              component={CreateKitForSale}
            />
            <PrivateRoute exact={true} isLoggedIn={isLoggedIn} path='/documents' component={Documents} />
            <PrivateRoute exact={true} isLoggedIn={isLoggedIn} path='/edit-stock/:id' component={CreateStock} />
            <PrivateRoute exact={true} isLoggedIn={isLoggedIn} path='/kit_details/:id' component={KitDetails} />
            <PrivateRoute
              key='kit-manually-details'
              exact={true}
              isLoggedIn={isLoggedIn}
              path='/kit-manually-details/:id'
              component={KitForSaleDetails}
            />
            <PrivateRoute
              key='single-item-details'
              exact={true}
              isLoggedIn={isLoggedIn}
              path='/single-item-details/:id'
              component={KitForSaleDetails}
            />
            {(!userHasOnlyLLRMS || noUserRoles) && (
              <PrivateRoute key='calendar' exact={true} isLoggedIn={isLoggedIn} path='/calendar' component={Calendar} />
            )}
            {hasLabLocationPermissions && (
              <PrivateRoute
                key='labs-location-calendar'
                exact={true}
                isLoggedIn={isLoggedIn}
                path='/labs-location-calendar'
                component={isLLEnabled ? Calendar : LabsLocationMessageWarning}
              />
            )}
            {hasAccessToIqsSscDeLocations && (
              <PrivateRoute
                key='iqs-calendar'
                exact={true}
                isLoggedIn={isLoggedIn}
                path='/iqs-calendar'
                component={Calendar}
              />
            )}
            <PrivateRoute key='loan-list' exact={true} isLoggedIn={isLoggedIn} path='/loan-list' component={LoanList} />
            <PrivateRoute
              key='labs-location-loan-list'
              exact={true}
              isLoggedIn={isLoggedIn}
              path='/labs-location-loan-list'
              component={LoanList}
            />
            {(!userHasOnlyLLRMS || noUserRoles) && (
              <PrivateRoute key='kit-loan' exact={true} isLoggedIn={isLoggedIn} path='/kit-loan' component={KitLoan} />
            )}
            {hasLabLocationPermissions && (
              <PrivateRoute
                key='labs-location-kit-loan'
                exact={true}
                isLoggedIn={isLoggedIn}
                path='/labs-location-kit-loan'
                component={LLKitLoan}
              />
            )}
            {!isUserPartner && (
              <PrivateRoute
                key='single-loan'
                exact={true}
                isLoggedIn={isLoggedIn}
                path='/single-loan'
                component={KitLoan}
              />
            )}
            {!isUserPartner && (
              <PrivateRoute
                key='course-loan'
                exact={true}
                isLoggedIn={isLoggedIn}
                path='/course-loan'
                component={KitLoan}
              />
            )}
            <PrivateRoute
              exact={true}
              isLoggedIn={isLoggedIn}
              path='/delivery-addresses'
              component={DeliveryAddresses}
            />
            
            {!isUserPartner && (
              <PrivateRoute
                exact={true}
                isLoggedIn={isLoggedIn}
                path={'/c2c/:id/view-c2c-loan'}
                component={CreateC2CLoan}
              />
            )}
            {!isUserPartner && (
              <PrivateRoute
                exact={true}
                isLoggedIn={isLoggedIn}
                path='/c2c/:id/maintain-c2c-loan'
                component={CreateC2CLoan}
              />
            )}
            {!isUserPartner &&
              transactionPaths.map((path, index) => (
                <PrivateRoute
                  key={`${path}${index}create`}
                  isLoggedIn={isLoggedIn}
                  path={`${path}/:id/create-c2c-loan`}
                  component={CreateC2CLoan}
                />
              ))}
            {!isUserPartner && !renderZmccDetailsComponent &&
              transactionPaths.map((path, index) => (
                <PrivateRoute
                  key={`${path}${index}id`}
                  isLoggedIn={isLoggedIn}
                  path={`${path}/:id`}
                  component={TransactionDetails}
                />
              ))}
            {!isUserPartner && !renderZmccDetailsComponent &&
              transactionPaths.map((path, index) => (
                <PrivateRoute key={`${path}${index}`} isLoggedIn={isLoggedIn} path={path} component={path === '/my-queries' ? MyQueries : Booking} />
              ))}
            {isUserPartner && !renderZmccDetailsComponent &&
              partnerTransactionPaths.map((path, index) => (
                <PrivateRoute
                  key={`l@l${path}${index}id`}
                  isLoggedIn={isLoggedIn}
                  path={`${path}/:id`}
                  component={TransactionDetails}
                />
              ))}
            {isUserPartner && !renderZmccDetailsComponent &&
              partnerTransactionPaths.map((path, index) => (
                <PrivateRoute key={`l@l${path}${index}`} isLoggedIn={isLoggedIn} path={path} component={Booking} />
              ))}
            {!isUserPartner && !renderZmccDetailsComponent &&(
              <PrivateRoute exact={true} isLoggedIn={isLoggedIn} path={'/c2c/:id'} component={TransactionDetails} />
            )}
            {!isUserPartner && <PrivateRoute exact={true} isLoggedIn={isLoggedIn} path='/c2c-loans' component={C2CLoans} />}
            <PrivateRoute
              exact={true}
              isLoggedIn={isLoggedIn}
              path='/subscriptions'
              component={SystemClassesSubscriptions}
            />
            {hasDustPermissions && !isUserPartner && (
              <PrivateRoute exact={true} isLoggedIn={isLoggedIn} path='/dusd/permissions' component={Permissions} />
            )}
            {hasDustTaskPermissions && !isUserPartner && (
              <PrivateRoute exact={true} isLoggedIn={isLoggedIn} path='/dusd/dusd-task' component={DusdTask} />
            )}
            {hasSubscriptionsPermissions && !isUserPartner && (
              <PrivateRoute exact={true} isLoggedIn={isLoggedIn} path='/dusd/subscriptions' component={Subscriptions} />
            )}
            {hasMaintainStatusPermissions && !isUserPartner && (
              <PrivateRoute
                exact={true}
                isLoggedIn={isLoggedIn}
                path='/dusd/maintain-status'
                component={MaintainStatus}
              />
            )}
            {hasActiveSystemsPermissions && (
              <PrivateRoute
                exact={true}
                isLoggedIn={isLoggedIn}
                path='/dust/active-systems'
                component={ActiveSystems}
              />
            )}
            {(isAdmin || hasMedUsWarehouseAccess || hasAccessToDlaLocations) && !isUserPartner && (
              <PrivateRoute
                exact={true}
                isLoggedIn={isLoggedIn}
                path='/administration/master-data'
                component={MasterDataTabs}
              />
            )}
            {(isAdmin || isShowBookingTeams) && !isUserPartner && (
              <PrivateRoute
                exact={true}
                isLoggedIn={isLoggedIn}
                path='/administration/export-data'
                component={ExportData}
              />
            )}
            {hasStockDashboardPermissions && !isUserPartner && (
              <PrivateRoute
                exact={true}
                isLoggedIn={isLoggedIn}
                path='/administration/stock-dashboard'
                component={StockDashboard}
              />
            )}
            {hasStatisticsPermissions && !isUserPartner &&(
              <PrivateRoute
                exact={true}
                isLoggedIn={isLoggedIn}
                path='/administration/statistics'
                component={AdministrationStatistics}
              />
            )}
            {isAdmin && !isUserPartner && (
              <PrivateRoute
                exact={true}
                isLoggedIn={isLoggedIn}
                path='/administration/interface-monitoring'
                component={InterfaceMonitoring}
              />
            )}
            {canViewSapErpMapping && !isUserPartner &&(
              <PrivateRoute
                exact={true}
                isLoggedIn={isLoggedIn}
                path='/administration/sap-erp-mapping'
                component={SapErpMapping}
              />
            )}

              <PrivateRoute
                exact={true}
                isLoggedIn={isLoggedIn}
                path='/administration/refurbishment-fees'
                component={RefurbishmentFees}
              />

            {!isUserPartner && (
              <PrivateRoute
                exact={true}
                isLoggedIn={isLoggedIn}
                path='/administration/status-list/:id'
                component={StatusList}
              />
            )}
            {!isUserPartner && (
              <PrivateRoute
                exact={true}
                isLoggedIn={isLoggedIn}
                path='/administration/status-list/:id/:demoUnitStatus'
                component={StatusList}
              />
            )}
            {hasUserPermissions && !isUserPartner && (
              <PrivateRoute
                exact={true}
                isLoggedIn={isLoggedIn}
                path='/administration/user-permissions'
                component={UserPermissions}
              />
            )}
            {isAdmin && !isUserPartner && (
              <PrivateRoute
                exact={true}
                isLoggedIn={isLoggedIn}
                path='/administration/kit-pictures'
                component={ManageKitPictures}
              />
            )}
            {isAdmin && !isUserPartner && (
              <PrivateRoute
                exact={true}
                isLoggedIn={isLoggedIn}
                path='/administration/partner-permissions'
                component={LLPermissions}
              />
            )}
            {(isAdmin || isLLManager) && (
              <PrivateRoute
                exact={true}
                isLoggedIn={isLoggedIn}
                path='/administration/labs-location-management'
                component={LLManagement}
              />
            )}
            {(isAdmin || isLabsLocationStockController) && (
              <PrivateRoute exact={true} isLoggedIn={isLoggedIn} path='/create-llkit' component={CreateLLKit} />
            )}
            {(isAdmin || isLabsLocationStockController) && (
              <PrivateRoute exact={true} isLoggedIn={isLoggedIn} path='/edit-llkit/:id' component={CreateLLKit} />
            )}
            {(isAdmin || isLabsLocationStockController) && (
              <PrivateRoute exact={true} isLoggedIn={isLoggedIn} path='/copy-llkit/:id' component={CreateLLKit} />
            )}
            {(isAdmin || isZMCCStockController) && (
              <PrivateRoute exact={true} isLoggedIn={isLoggedIn} path='/create-zmcc-kit' component={CreateZmccKit} />
            )}
            {(isAdmin || isZMCCStockController) && (
              <PrivateRoute exact={true} isLoggedIn={isLoggedIn} path='/create-zmcc-kit/:id' component={CreateZmccKit} />
            )}
            {(isAdmin || isZMCCStockController) && (
              <PrivateRoute exact={true} isLoggedIn={isLoggedIn} path='/edit-zmcc-kit/:id' component={CreateZmccKit} />
            )}
            {(isAdmin || isZMCCStockController) && (
              <PrivateRoute exact={true} isLoggedIn={isLoggedIn} path='/copy-zmcc-kit/:id' component={CreateZmccKit} />
            )}
            {loansSubMenu && loansSubMenu.length >0 && loansSubMenu[2].translationSubMenu === 'SEM' &&
            <PrivateRoute exact={true} isLoggedIn={isLoggedIn} path='/SEM' component={SemBooking} />}
             {loansSubMenu && loansSubMenu.length >0 && loansSubMenu[1].translationSubMenu === 'XB' &&
            <PrivateRoute exact={true} isLoggedIn={isLoggedIn} path='/XB' component={SemBooking} />}
             {loansSubMenu && loansSubMenu.length >0 && loansSubMenu[3].translationSubMenu === 'XRM' &&
            <PrivateRoute exact={true} isLoggedIn={isLoggedIn} path='/XRM' component={SemBooking} />}
            {loansSubMenu && loansSubMenu.length >0 && loansSubMenu[0].translationSubMenu === 'LM' &&
            <PrivateRoute exact={true} isLoggedIn={isLoggedIn} path='/LM' component={SemBooking} />}
            
            <PrivateRoute exact={true} isLoggedIn={isLoggedIn} path='/SEM/:id' component={SemBooking} />
            <PrivateRoute exact={true} isLoggedIn={isLoggedIn} path='/XB/:id' component={SemBooking} />
            <PrivateRoute exact={true} isLoggedIn={isLoggedIn} path='/XRM/:id' component={SemBooking} />
            <PrivateRoute exact={true} isLoggedIn={isLoggedIn} path='/LM/:id' component={SemBooking} />
              <PrivateRoute
                key='zmcc-calendar'
                exact={true}
                isLoggedIn={isLoggedIn}
                path='/zmcc-calendar'
                component={ Calendar}
              />
              {renderZmccDetailsComponent &&
              zmccTransactionPaths.map((path, index) => (
                <PrivateRoute
                  key={`zmcc${path}${index}id`}
                  isLoggedIn={isLoggedIn}
                  path={`${path}/:id/:type`}
                  component={ZmccTransactionDeatils}
                />
              ))}
              <PrivateRoute exact={true} isLoggedIn={isLoggedIn} path='/my-schedule' component={MainMySchedule} />
              <PrivateRoute exact={true} isLoggedIn={isLoggedIn} path='/create-iqs-request' component={IqsNewRequest} />
            <Redirect to='/home' />
          </Switch>
        ) : (
          <Route path='*' component={hasNoAccessToStock && isShowStockPopup === null? () => (<NoStockAccessPopup chnageTestValue={chnageTestValue} history ={this.props.history} user={user}/>):Homepage} />
        )}
      </div>
    );
  }
}

export default withRouter(Routes);
