import { fetchMasterDataService } from 'library/api/master-data.service';
import { URLS } from 'library/common/commonConstants/ApiUrlConstants';
import { FETCH_ALLIQS_REQUEST_DROPDOWN_DATA } from './IqsNewRequest.constants';
import { fetchUserList } from 'modules/KitLoan/KitLoanActions';

export const fetchDropdownData = () => async dispatch => {
  try {
    const response = await fetchMasterDataService(URLS.getAllIqsRequestDropDownData);
    dispatch({
      type: FETCH_ALLIQS_REQUEST_DROPDOWN_DATA,
      payload: response.data,
    });
    dispatch(fetchUserList());
    return { success: true };
  } catch (error) {
    dispatch({
      type: FETCH_ALLIQS_REQUEST_DROPDOWN_DATA,
      payload: {},
    });
    return { success: false };
  }
};
