import Validators from 'library/utilities/Validators';

export const FETCH_ALLIQS_REQUEST_DROPDOWN_DATA = 'FETCH_ALLIQS_REQUEST_DROPDOWN_DATA';

export const IqsRequestBreadcrumbData = [
  {
    name: 'homepage',
    url: '/home',
  },
  {
    name: 'iqsRequests',
  },
  {
    name: 'createOrModifyRequest',
  },
];

export const headerModel = [
  {
    label: 'requestTitle',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'requestTitle',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-6',
    disabled: false,
  },
  {
    label: 'requestId',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'requestId',
    validators: [],
    required: false,
    styleClass: 'col-3',
    disabled: true,
  },
  {
    label: 'status',
    value: '',
    type: 'dropdown',
    placeholder: 'pleaseSelect',
    field: 'status',
    validators: [],
    required: false,
    styleClass: 'col-3',
    options: [],
    hasSection: false,
    multiSelect: false,
    filter: true,
    idKey: 'id',
    displayValue: [{ key: 'name', separator: ' ' }],
    showClearIcon: false,
  },
  {
    label: 'iqsRequestType',
    value: '',
    type: 'dropdown',
    placeholder: 'pleaseSelect',
    field: 'iqsRequestType',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-3',
    options: [],
    hasSection: false,
    multiSelect: false,
    filter: true,
    idKey: 'id',
    displayValue: [{ key: 'name', separator: ' ' }],
    showClearIcon: false,
  },
  {
    label: 'executionLocation',
    value: '',
    type: 'dropdown',
    placeholder: 'pleaseSelect',
    field: 'executionLocation',
    validators: [],
    required: false,
    styleClass: 'col-3',
    options: [],
    hasSection: false,
    multiSelect: false,
    filter: true,
    idKey: 'id',
    displayValue: [{ key: 'name', separator: ' ' }],
    showClearIcon: false,
  },
  {
    label: 'processingLocation',
    value: '',
    type: 'dropdown',
    placeholder: 'pleaseSelect',
    field: 'processingLocation',
    validators: [],
    required: false,
    styleClass: 'col-3',
    options: [],
    hasSection: false,
    multiSelect: false,
    filter: true,
    idKey: 'id',
    displayValue: [{ key: 'name', separator: ' ' }],
    showClearIcon: false,
  },
  {
    label: 'requestDescription',
    value: '',
    type: 'textarea',
    placeholder: '',
    field: 'requestDescription',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-6',
    disabled: false,
  },
  {
    label: 'commentText',
    value: '',
    type: 'textarea',
    placeholder: '',
    field: 'commentText',
    validators: [],
    required: false,
    styleClass: 'col-6',
    disabled: false,
  },
];
