import { connect } from 'react-redux';
import IqsNewRequest from './IqsNewRequest.component';
import { fetchDropdownData } from './IqsNewRequest.actions';

const mapStateToProps = ({ authReducer, iqsRequestReducer, languageReducer }) => ({
  user: authReducer.user,
  allDropdownData: iqsRequestReducer.allDropdownData,
  language: languageReducer
});

export default connect(mapStateToProps, { fetchDropdownData })(IqsNewRequest);
