import Modal from 'library/common/commonComponents/Modal';
import React from 'react';
import strings from 'resources/locales/Translate';
import { useAddLocationComment } from './AddLocationComment.hook';
import FormComponent from 'library/common/commonComponents/FormComponent/Optimized';
import { createOrEditIqsComment, fetchIqsLocations } from 'modules/Calendar/CalendarActions';
import { connect } from 'react-redux';

export const AddLocationComment = ({ id, iqsCommentLocations, fetchIqsLocations, createOrEditIqsComment, selectedCommentEvent, setSelectedCommentEvent }) => {
  const { addLocationCommentModel, handleForm, locationCommentFormData, onSaveClick, onCloseClick, error, loading } = useAddLocationComment({
    id,
    iqsCommentLocations,
    fetchIqsLocations,
    createOrEditIqsComment,
    selectedCommentEvent,
    setSelectedCommentEvent
  });
  return (
    <div>
      <Modal
        id={id}
        title={selectedCommentEvent?.commentId ? strings.editLocationComment : strings.createNewLocationComment}
          confirmAction={onSaveClick}
        confirmTitle={strings.confirm}
          loading={loading}
          onCloseClick={onCloseClick}
        className='add-location-comment-popup-width'
        newVersionUi={true}
        titleOfCancel={strings.cancel}
      >
        <div className='mt-4'>
          <FormComponent
            formName='addLocationCommentForm'
            model={addLocationCommentModel()}
            formValue={locationCommentFormData.formData}
            onChange={handleForm}
          />
        </div>

        {error && <span className='text-danger error-block'>{error}</span>}
      </Modal>
    </div>
  );
};

const mapStateToProps = ({ authReducer, languageReducer, calendar }) => ({
  language: languageReducer.language,
  user: authReducer.user,
  iqsCommentLocations: calendar.iqsCommentLocations,
});

export default connect(mapStateToProps, {fetchIqsLocations, createOrEditIqsComment })(AddLocationComment);
