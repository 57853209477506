import { companyPopupModel } from './CompanyPopup.constants';

export const useCompanyPopup = ({id, allDropdownData}) => {
  const getcompanyPopupModel = () => {
    const model = [...companyPopupModel];
    model[11].options = allDropdownData?.IqsIndustrialSegment || [];
    model[13].options = allDropdownData?.IqRequestCompanyNdaType || [];
    return model;
  };

  return {
    getcompanyPopupModel,
  };
};
