import React from 'react';
import Scheduler, { ViewTypes } from 'react-big-scheduler';
import 'react-big-scheduler/lib/css/style.css';
import withDndContext from 'library/utilities/withDndContext';
import './iqsCalendar.styles.scss';
import SlotItem from './components/SlotItem';
import { useAppSchedular } from './IQSAppSchedular.hook';
import CalendarHeader from './components/CalendarHeader';
// import Scheduler from 'library/common/commonComponents/ReactBigScheduler';
import CalendarLegends from './components/CalendarLegends';
import { connect } from 'react-redux';
import { fetchIqsCalendarResources } from './IQSCalendar.actions';
import AddLocationComment from './components/AddLocationComment/AddLocationComment.component';
import { fetchIqsCommentsList } from '../CalendarActions';
import ComfirmBookingPopup from './components/ComfirmBookingPopup/ComfirmBookingPopup.component';
import { toggleActionMessage } from 'library/common/commonActions/AppActionsActions';

const IQSCalendar = props => {
  const {
    calendarConfig,
    prevClick,
    nextClick,
    onSelectDate,
    onViewChange,
    eventClicked,
    renderDayHeader,
    selectedViewType,
    slotTempletResolver,
    toggleExpandFunc,
    schedulerRef,
    todayClick,
    selectedDate,
    slotItemPopover,
    iqsCalendarResources,
    filter,
    onAddCommnetClick,
    selectedCommentEvent,
    setSelectedCommentEvent,
    newEvent,
    updateEventStart,
    updateEventEnd,
    moveEvent,
    onRemoveEvent,
    selectedSystem,
    eventSelectedPopupOpen,
    setEventSelectedPopupOpen,
    conflictOccurred,
    setSelectedSystem,
    user,
    singleEventClickedId
  } = useAppSchedular(props);

  // Custom Event Template
  const customEventTemplateResolver = (_, event, bgColor, isStart, isEnd, mustAddCssClass, mustBeHeight) => (
    (
      <SlotItem
        user={user}
        mustAddCssClass={mustAddCssClass}
        mustBeHeight={mustBeHeight}
        event={event}
        selectedViewType={selectedViewType}
        calendarConfig={calendarConfig}
        handleRemoveEvent={(calendarConfig, event) => onRemoveEvent(calendarConfig, event)}
      />
    )
  );
  return (
    <>
      <CalendarHeader
        onViewChange={onViewChange}
        selectedViewType={selectedViewType}
        prevClick={prevClick}
        nextClick={nextClick}
        todayClick={todayClick}
        onSelectDate={onSelectDate}
        selectedDate={selectedDate}
      />
      {calendarConfig && iqsCalendarResources && iqsCalendarResources.length > 0 && (
        <div
          className={`iqs-calendar-container ${
            selectedViewType == ViewTypes.Custom ? 'hide-border-day-week-view' : ''
          }`}
          ref={schedulerRef}
        >
          <Scheduler
            schedulerData={calendarConfig}
            prevClick={prevClick}
            nextClick={nextClick}
            onSelectDate={onSelectDate}
            onViewChange={onViewChange}
            eventItemClick={eventClicked}
            eventItemTemplateResolver={customEventTemplateResolver}
            nonAgendaCellHeaderTemplateResolver={renderDayHeader}
            slotItemTemplateResolver={slotTempletResolver}
            toggleExpandFunc={toggleExpandFunc}
            eventItemPopoverTemplateResolver={slotItemPopover}
            newEvent={newEvent}
            updateEventStart={updateEventStart}
            updateEventEnd={updateEventEnd}
            moveEvent={moveEvent}
            conflictOccurred={conflictOccurred}
          />
        </div>
      )}
      <CalendarLegends onAddCommnetClick={onAddCommnetClick} />
      <AddLocationComment
        id={'add-location-comment-modal'}
        selectedCommentEvent={selectedCommentEvent}
        setSelectedCommentEvent={setSelectedCommentEvent}
      />
        <ComfirmBookingPopup
          id={'confirm-booking-modal'}
          calendarConfig={calendarConfig}
          selectedSystem={selectedSystem}
          eventSelectedPopupOpen={eventSelectedPopupOpen}
          setEventSelectedPopupOpen={setEventSelectedPopupOpen}
          setSelectedSystem={setSelectedSystem}
          singleEventClickedId={singleEventClickedId}
        />
    </>
  );
};

export default withDndContext(
  connect(null, {
    fetchIqsCalendarResources,
    fetchIqsCommentsList,
    toggleActionMessage
  })(IQSCalendar),
);
