import Validators from "library/utilities/Validators";

export const crmAndCustomerModel = [
  {
    label: 'opportunityNumber',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'opportunityNumber',
    validators: [],
    required: false,
    styleClass: 'col-3',
    disabled: false,
    newRefreshIcon : true,
  },
  {
    label: 'salesNumber',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'salesNumber',
    validators: [],
    required: false,
    styleClass: 'col-3',
    disabled: false,
  },
  {
    label: 'companySapId',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'companySapId',
    validators: [],
    required: false,
    styleClass: 'col-3',
    disabled: false,
    newRefreshIcon : true,
  },
  {
    label: 'customer',
    value: '',
    type: 'dropdown',
    placeholder: 'pleaseSelect',
    field: 'customer',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-3',
    options: [],
    hasSection: false,
    multiSelect: false,
    filter: true,
    idKey: 'id',
    displayValue: [{ key: 'name', separator: ' ' }],
    showClearIcon: false,
  },
  {
    label: 'companyName',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'companyName',
    validators: [],
    required: false,
    styleClass: 'col-3',
    disabled: true,
  },
  {
    label: 'companyGroup',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'companyGroup',
    validators: [],
    required: false,
    styleClass: 'col-3',
    disabled: true,
  },
  {
    label: 'comapnyStreetHouseNumber',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'comapnyStreetHouseNumber',
    validators: [],
    required: true,
    styleClass: 'col-3',
    disabled: true,
  },
  {
    label: 'companyPostalCode',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'companyPostalCode',
    validators: [],
    required: false,
    styleClass: 'col-3',
    disabled: true,
  },
  {
    label: 'companyCity',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'companyCity',
    validators: [],
    required: false,
    styleClass: 'col-3',
    disabled: true,
  },
  {
    label: 'companyCountry',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'companyCountry',
    validators: [],
    required: false,
    styleClass: 'col-3',
    disabled: true,
  },
  {
    label: 'companyPhone',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'companyPhone',
    validators: [],
    required: false,
    styleClass: 'col-3',
    disabled: true,
  },
  {
    label: 'companyEmail',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'companyEmail',
    validators: [],
    required: false,
    styleClass: 'col-3',
    disabled: true,
  },
  {
    label: 'companyWebsite',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'companyWebsite',
    validators: [],
    required: false,
    styleClass: 'col-3',
    disabled: true,
  },
  {
    label: 'industrialSegment',
    value: '',
    type: 'dropdown',
    placeholder: 'pleaseSelect',
    field: 'industrialSegment',
    validators: [],
    required: false,
    styleClass: 'col-3',
    options: [],
    hasSection: false,
    multiSelect: false,
    filter: true,
    idKey: 'id',
    displayValue: [{ key: 'name', separator: ' ' }],
    showClearIcon: false,
    disabled: true,
  },
  {
    label: 'industrialSubSegment',
    value: '',
    type: 'dropdown',
    placeholder: 'pleaseSelect',
    field: 'industrialSubSegment',
    validators: [],
    required: false,
    styleClass: 'col-3',
    options: [],
    hasSection: false,
    multiSelect: false,
    filter: true,
    idKey: 'id',
    displayValue: [{ key: 'name', separator: ' ' }],
    showClearIcon: false,
    disabled: true,
  },
  {
    label: 'ndaType',
    value: '',
    type: 'dropdown',
    placeholder: 'pleaseSelect',
    field: 'ndaType',
    validators: [],
    required: false,
    styleClass: 'col-3',
    options: [],
    hasSection: false,
    multiSelect: false,
    filter: true,
    idKey: 'id',
    displayValue: [{ key: 'name', separator: ' ' }],
    showClearIcon: false,
    disabled: true,
  },
  {
    label: 'ndaValidUntil',
    value: '',
    type: 'date',
    placeholder: 'pleaseSelect',
    field: 'ndaValidUntil',
    validators: [],
    required: false,
    styleClass: 'col-sm-3',
    dateFormat: 'DD.MM.YYYY',
    disabled: true,
    tileDisabled: [],
  },
  {
    label: 'aukomMember',
    selected: false,
    type: 'enhanced-checkbox',
    field: 'aukomMember',
    styleClass: 'col-sm-3',
    newuiVersionStyle: true,
    labelTopPosition: true,
    disabled: true,
  },
];

export const customerContactModel = [
  {
    label: 'customerContactCrmId',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'customerContactCrmId',
    validators: [],
    required: false,
    styleClass: 'col-3',
    disabled: false,
    newRefreshIcon : true,
  },
  {
    label: 'customerContact',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'customerContact',
    validators: [],
    required: false,
    styleClass: 'col-3',
    disabled: false,
    newRefreshIcon : true,
  },
  {
    label: 'customerContactFirstName',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'customerContactFirstName',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-3',
    disabled: false,
  },
  {
    label: 'customerContactEmail',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'customerContactEmail',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-3',
    disabled: false,
  },
  {
    label: 'customerContactRole',
    value: '',
    type: 'dropdown',
    placeholder: 'pleaseSelect',
    field: 'ndaType',
    validators: [],
    required: true,
    styleClass: 'col-3',
    options: [],
    hasSection: false,
    multiSelect: false,
    filter: true,
    idKey: 'id',
    displayValue: [{ key: 'name', separator: ' ' }],
    showClearIcon: false,
  },
];

export const contactCreatorModel = [
  {
    label: 'creator',
    value: '',
    type: 'dropdown',
    placeholder: 'pleaseSelect',
    field: 'creator',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-3',
    options: [],
    hasSection: false,
    multiSelect: false,
    filter: true,
    idKey: 'accountId',
    displayValue: [
      { key: 'lastName', separator: ' ' },
      { key: 'firstName', separator: '' },
    ],
    showClearIcon: false,
  },
  {
    label: 'salesRep',
    value: '',
    type: 'dropdown',
    placeholder: 'pleaseSelect',
    field: 'salesRep',
    validators: [],
    required: true,
    styleClass: 'col-3',
    options: [],
    hasSection: false,
    multiSelect: false,
    filter: true,
    idKey: 'accountId',
    displayValue: [
      { key: 'lastName', separator: ' ' },
      { key: 'firstName', separator: '' },
    ],
    showClearIcon: false,
  },
  {
    label: 'projectResponsible',
    value: '',
    type: 'dropdown',
    placeholder: 'pleaseSelect',
    field: 'projectResponsible',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-3',
    options: [],
    hasSection: false,
    multiSelect: false,
    filter: true,
    idKey: 'accountId',
    displayValue: [
      { key: 'lastName', separator: ' ' },
      { key: 'firstName', separator: '' },
    ],
    showClearIcon: false,
  },
  {
    label: 'projectManager',
    value: '',
    type: 'dropdown',
    placeholder: 'pleaseSelect',
    field: 'projectManager',
    validators: [],
    required: true,
    styleClass: 'col-3',
    options: [],
    hasSection: false,
    multiSelect: false,
    filter: true,
    idKey: 'accountId',
    displayValue: [
      { key: 'lastName', separator: ' ' },
      { key: 'firstName', separator: '' },
    ],
    showClearIcon: false,
  },
];
  
  export const addZeissCompanayModel = [
    {
      label: 'zeissPerson',
      value: '',
      type: 'dropdown',
      placeholder: 'pleaseSelect',
      field: 'zeissPerson',
      validators: [],
      required: true,
      styleClass: 'col-6 pl-0',
      options: [],
      hasSection: false,
      multiSelect: false,
      filter: true,
      idKey: 'accountId',
      displayValue: [
        { key: 'lastName', separator: ' ' },
        { key: 'firstName', separator: '' },
      ],
      showClearIcon: false,
    },
    {
      label: 'roleText',
      value: '',
      type: 'dropdown',
      placeholder: 'pleaseSelect',
      field: 'role',
      validators: [],
      required: true,
      styleClass: 'col-6',
      options: [],
      hasSection: false,
      multiSelect: false,
      filter: false,
      idKey: 'id',
      displayValue: [{ key: 'name', separator: ' ' }],
      showClearIcon: false,
    },
  ];
  