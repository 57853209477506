import Validators from 'library/utilities/Validators';

export const companyPopupModel = [
  {
    label: 'sapIdAvailable',
    value: '',
    type: 'enhanced-circle-RadioButton',
    placeholder: '',
    field: 'sapIdAvailable',
    validators: [],
    required: false,
    styleClass: 'col-6',
    options: [
      {
        id: 'YES',
        value: 'yes',
      },
      {
        id: 'NO',
        value: 'no',
      },
    ],
  },
  {
    label: 'sapId',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'sapId',
    validators: [],
    required: false,
    styleClass: 'col-6',
    disabled: false,
  },
  {
    label: 'companyName',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'companyName',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-12',
    disabled: false,
  },
  {
    label: 'companyGroup',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'companyGroup',
    validators: [],
    required: false,
    styleClass: 'col-6',
    disabled: false,
  },
  {
    label: 'comapnyStreetHouseNumber',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'comapnyStreetHouseNumber',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-6',
    disabled: false,
  },
  {
    label: 'companyPostalCode',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'companyPostalCode',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-6',
    disabled: false,
  },
  {
    label: 'companyCity',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'companyCity',
    validators: [],
    required: false,
    styleClass: 'col-6',
    disabled: false,
  },
  {
    label: 'companyCountry',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'companyCountry',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-6',
    disabled: false,
  },
  {
    label: 'companyPhone',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'companyPhone',
    validators: [],
    required: false,
    styleClass: 'col-6',
    disabled: false,
  },
  {
    label: 'companyEmail',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'companyEmail',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-6',
    disabled: false,
  },
  {
    label: 'companyWebsite',
    value: '',
    type: 'text',
    placeholder: '',
    field: 'companyWebsite',
    validators: [],
    required: false,
    styleClass: 'col-6',
    disabled: false,
  },
  {
    label: 'industrialSegment',
    value: '',
    type: 'dropdown',
    placeholder: 'pleaseSelect',
    field: 'industrialSegment',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-6',
    options: [],
    hasSection: false,
    multiSelect: false,
    filter: true,
    idKey: 'id',
    displayValue: [{ key: 'name', separator: ' ' }],
    showClearIcon: false,
  },
  {
    label: 'industrialSubSegment',
    value: '',
    type: 'dropdown',
    placeholder: 'pleaseSelect',
    field: 'industrialSubSegment',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-6',
    options: [],
    hasSection: false,
    multiSelect: false,
    filter: true,
    idKey: 'id',
    displayValue: [{ key: 'name', separator: ' ' }],
    showClearIcon: false,
  },
  {
    label: 'ndaType',
    value: '',
    type: 'dropdown',
    placeholder: 'pleaseSelect',
    field: 'ndaType',
    validators: [],
    required: false,
    styleClass: 'col-6',
    options: [],
    hasSection: false,
    multiSelect: false,
    filter: true,
    idKey: 'id',
    displayValue: [{ key: 'name', separator: ' ' }],
    showClearIcon: false,
  },
  {
    label: 'ndaValidUntil',
    value: '',
    type: 'date',
    placeholder: 'pleaseSelect',
    field: 'ndaValidUntil',
    validators: [{ check: Validators.required, message: 'requiredErrorMessage' }],
    required: true,
    styleClass: 'col-6',
    dateFormat: 'DD.MM.YYYY',
    disabled: true,
    tileDisabled: [],
  },
  {
    label: 'aukomMember',
    value: '',
    type: 'enhanced-circle-RadioButton',
    placeholder: '',
    field: 'aukomMember',
    validators: [],
    required: false,
    styleClass: 'col-6',
    options: [
      {
        id: 'YES',
        value: 'yes',
      },
      {
        id: 'NO',
        value: 'no',
      },
    ],
  },
  {
    label: 'commentText',
    value: '',
    type: 'textarea',
    placeholder: '',
    field: 'commentText',
    validators: [],
    required: false,
    styleClass: 'col-12',
    disabled: false,
  },
];
