import { customerContactPopupModel } from './CustomerContactPopup.constants';

export const useCustomerContactPopup = ({ id, allDropdownData }) => {
  const getCustomerContactPopupModel = () => {
    const model = [...customerContactPopupModel];
    model[9].options = allDropdownData?.IqRequestCustomerContactRole || [];
    return model;
  };

  return {
    getCustomerContactPopupModel,
  };
};
